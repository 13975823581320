const axleSyncDriverAdd = {
	urlPOST: "api/axle_import/carriers/:carrierId/axle_driver",
	idKey: "id",
	triggerEvent: ["profileRevision", "setupDataRevision"],
	listenEvent: "profileRevision",
	header: { create: { label: "Add Driver" } },
	size: "max-w-m",
	content: {
		create: {
			items: [
				{
					key: "name",
					type: "text",
					label: "Name",
					required: true,
				},
				{
					key: "email",
					type: "email",
					label: "Email",
					required: true,
				},
				{
					key: "phone",
					type: "phone",
					label: "Phone",
					required: false,
				},
				{ type: "action", action: "SUBMIT", label: "Submit" },
			],
		},
	},
};

export default axleSyncDriverAdd;
