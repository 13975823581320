import { TRAILER_LITE_FIELDS } from "@smarthop/form/configs/trailerForm";

const axleSyncTruckAdd = {
	urlPOST: "api/axle_import/carriers/:carrierId/axle_truck",
	idKey: "id",
	triggerEvent: ["profileRevision", "setupDataRevision"],
	listenEvent: "profileRevision",
	header: { create: { label: "Add Truck" } },
	size: "max-w-m",
	content: {
		create: {
			items: [
				{
					type: "text",
					key: "name",
					label: "Name",
					required: true,
				},
				TRAILER_LITE_FIELDS()[0],
				{ type: "action", action: "SUBMIT", label: "Submit" },
			],
		},
	},
};

export default axleSyncTruckAdd;
