import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Typography } from "@material-ui/core";

import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { updateCarrierInformation } from "app/services/carrierServices";
import { showSnackbar } from "app/main/utils/snackbarUtil";

const HUBSPOT_MEETING_URL =
	process.env.REACT_APP_ENV === "PRD"
		? "https://meetings.hubspot.com/will-elliot/smarthop-onboarding-call?embed=true"
		: "https://meetings.hubspot.com/miguel-sucre?embed=true";

function BookMeetingView({ data, setLoading }) {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const [error, setError] = useState(false);
	const skipMeeting = useSelector(({ tools }) => tools.revision["skipMeeting"]);

	const updateMeetingFlag = async (meetingBooked) => {
		setLoading?.(true);
		setError(false);
		try {
			const sfUpdatedCarrierId = data?.setup_onboarding_carrier_id || data?._id;
			await updateCarrierInformation(data?._id, {
				meetingScheduled: meetingBooked,
				forceFlagUpdate: true,
				sfUpdatedCarrierId,
				finishSetupOnboarding: true,
			});
			setLoading?.(false);
			dispatch(incrementDataRevision({ event: "setupRevision" }));
		} catch (e) {
			setLoading?.(false);
			setError(true);
			showSnackbar(snackbar, e.errors?.[0]?.message ?? "Opps, failed to load data...", "error");
		}
	};

	useEffect(() => {
		const handleMessage = (event) => {
			if (event.origin.includes("hubspot.com")) {
				if (event.data?.meetingBookSucceeded) {
					updateMeetingFlag(true);
				}
			}
		};
		window.addEventListener("message", handleMessage);
		return () => window.removeEventListener("message", handleMessage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (skipMeeting) updateMeetingFlag(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [skipMeeting]);

	let hubspotURL = `${HUBSPOT_MEETING_URL}&email=${encodeURIComponent(data?.user_email)}`;
	if (data?.user_first_name) hubspotURL = `${hubspotURL}&firstName=${data?.user_first_name}`;
	if (data?.user_last_name) hubspotURL = `${hubspotURL}&lastname=${data?.user_last_name}`;

	return (
		<div className="w-full md:w-10/12">
			<div className="scheduling-widget-wrapper">
				<Helmet>
					<script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js" />
				</Helmet>
				<div className="meetings-iframe-container" data-src={hubspotURL} />
			</div>
			{error ? (
				<div className="flex flex-row items-center justify-center my-40">
					<Typography color="textPrimary" className="text-14 mt-16 font-normal text-red">
						Error scheduling the meeting, please try again...
					</Typography>
				</div>
			) : null}
		</div>
	);
}

export default BookMeetingView;
