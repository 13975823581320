import clsx from "clsx";
import moment from "moment";
import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { Typography, Icon, makeStyles, IconButton, CircularProgress } from "@material-ui/core";
import { isEnabled } from "app/services/featureStorageService";

import ResourceView from "app/main/tools/ResourceView";
import SearchVersionSwitch from "./SearchVersionSwitch";

//Const
const _MAX_TABS = (creds) => {
	return creds === 1 ? 3 : 5;
};

const useStyles = makeStyles({
	"@keyframes flicker": { from: { opacity: 1 }, to: { opacity: 0.2 } },
	flicker: {
		animationName: "$flicker",
		animationDuration: "1000ms",
		animationIterationCount: "infinite",
		animationDirection: "alternate",
		animationTimingFunction: "ease-in-out",
	},
	withAnimation: ({ disabled }) => ({
		animationPlayState: disabled ? "paused" : "running",
	}),
});

function TabComponent({
	id,
	selectedTab,
	state,
	isEmpty,
	isAuthenticated,
	flicker,
	withAnimation,
	counters,
	title,
	subtitle,
	tabWidth,
	processing,
	onSelectTab,
	onCloseTab,
	creatingSearch,
	blockSearch,
}) {
	if (blockSearch) return null;
	const currentTab = selectedTab === id;
	const disable = !currentTab && creatingSearch;
	return (
		<div
			key={"tab_index_" + id}
			onClick={!disable ? (e) => onSelectTab(e, id) : null}
			className={clsx(
				"flex h-48 px-10",
				"items-center justify-center rounded-tl-lg rounded-tr-lg",
				currentTab
					? "bg-white cursor-pointer"
					: disable
					? "opacity-50 bg-grey-50 cursor-wait"
					: "opacity-80 bg-grey-50 hover:bg-grey-100 cursor-pointer"
			)}
			style={{ minWidth: tabWidth }}
		>
			{processing && <CircularProgress size={14} />}
			{!isEmpty && (
				<div className={clsx("flex flex-col min-w-20")}>
					<div
						className={clsx(
							(!isAuthenticated || state === "EXPIRED") && !!state
								? `text-red flex flex-row items-center my-2`
								: !state
								? `text-orange flex flex-row items-center my-2`
								: `${flicker} ${withAnimation} text-green flex flex-row items-center my-2`
						)}
					>
						<Icon className={"text-18"}>
							{!isAuthenticated
								? "cloud_off"
								: !state && !processing
								? "refresh"
								: state === "EXPIRED"
								? "stop_circle"
								: processing
								? ""
								: "cached"}
						</Icon>
						{isEnabled("QA_SEARCH_STATUS_INFO") && counters?.refreshRate > 0 && (
							<Typography className={clsx(`px-2 text-10 text-left`)}>{counters?.refreshRate}x</Typography>
						)}
						{isEnabled("QA_SEARCH_STATUS_INFO") && counters?.isVisible !== undefined && (
							<div
								className={`px-1 flex items-center justify-center w-6 h-6 md:h-8 md:w-8 rounded-full font-medium text-center ${
									counters?.isVisible ? "bg-green-400" : "bg-red-400"
								}`}
							></div>
						)}
						{isEnabled("QA_SEARCH_STATUS_INFO") && counters?.isHidden !== undefined && (
							<div
								className={`px-1 flex items-center justify-center w-6 h-6 md:h-8 md:w-8 rounded-full font-medium text-center ${
									counters?.isHidden ? "bg-green-400" : "bg-red-400"
								}`}
							></div>
						)}
					</div>
					<div className="flex flex-row">
						{counters?.new > 0 && (
							<Typography
								className={clsx(
									"text-11 text-center px-6 pt-1 pb-1 rounded-full bg-cyan-700 text-white font-bold -mt-2 mb-2"
								)}
							>
								{counters?.new}
							</Typography>
						)}
						{isEnabled("QA_SEARCH_STATUS_INFO") && counters?.detailsRequests >= 0 && (
							<div className="px-6 pt-1 pb-1 rounded-full bg-gray-400 -mt-2 mb-2 flex flex-row">
								<Typography className={clsx("px-2 text-11 text-center  text-white font-bold ")}>
									{counters?.detailsRequests}
									{counters?.availabilityRate ? " - " + counters?.availabilityRate + "x" : ""}
								</Typography>
								{counters.detailsErrors > 0 && (
									<Typography className={clsx("px-2 text-11 text-center  text-red font-bold ")}>
										{counters?.detailsErrors}
									</Typography>
								)}
							</div>
						)}
					</div>
				</div>
			)}
			<div className={clsx("flex flex-1 flex-col items-center justify-center")}>
				<Typography
					color="primary"
					className={clsx("px-14 text-12 font-normal max-w-210 truncate")}
					style={{ maxWidth: tabWidth - 70 }}
				>
					{isEmpty ? "New Search" : title}
				</Typography>
				{subtitle && (
					<Typography
						color="primary"
						className={clsx("px-14 text-12 font-normal -mt-4 max-w-210 truncate")}
						style={{ maxWidth: tabWidth - 70 }}
					>
						{subtitle}
					</Typography>
				)}
			</div>
			{!isEmpty && (
				<IconButton
					key="close"
					aria-label="Close"
					className={clsx("-ml-12 -mr-10")}
					color="inherit"
					onClick={(e) => onCloseTab(e, id)}
				>
					<Icon className={clsx("text-20 text-grey-600")}>close</Icon>
				</IconButton>
			)}
			{isEmpty && <ResourceView type={"tutorial"} tutorialId={"SEARCH_TUTORIAL"} classes={{ icon: "mt-2" }} />}
		</div>
	);
}

function SearchTabs({ currentSearchId, searches, onSelectTab, onCloseTab, nativeMobile }) {
	const { flicker, withAnimation } = useStyles({ disabled: false });
	const isAuthenticated = useSelector(({ socket }) => socket.connection.isAuthenticated);

	const [screenWidth, setScreenWidth] = useState(parseInt(window.innerWidth / 50) * 50);
	const [mobile, setMobile] = useState(window.innerWidth < 960);

	const user = useSelector(({ auth }) => auth.user);
	const blockTabs = useSelector(({ search }) => search.searchV3Slice.blockTabs);

	//If user has more than 1 carrier allow the max tabs, otherwise check carrier credentials
	const tabsLimit =
		user?.linkedAccounts.filter((account) => account.carrier?.accountStatus === "ACTIVE")?.length >= 2
			? _MAX_TABS(2)
			: !!user?.linkedAccounts.find(
					(account) => account.datCredentialCount > 1 && account.carrier?.accountStatus === "ACTIVE"
			  )
			? _MAX_TABS(2)
			: _MAX_TABS(1);

	useEffect(() => {
		function handleResize() {
			let innerWidth = window.innerWidth;
			innerWidth = parseInt(innerWidth / 50) * 50;
			if (innerWidth !== screenWidth) setScreenWidth(innerWidth);

			let isNewWidthMobile = window.innerWidth < 960;
			if (isNewWidthMobile !== mobile) setMobile(isNewWidthMobile);
		}
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	});

	// Monitoring screen width to adjust tab width
	let tabWidth = searches && Object.keys(searches).length > 0 ? screenWidth / Object.keys(searches).length : 0;
	tabWidth = tabWidth > 360 ? 360 : tabWidth < 210 ? 210 : tabWidth;
	if (isEnabled("QA_SEARCH_STATUS_INFO")) tabWidth = tabWidth + 50;

	let openSearches = searches ?? {};

	let orderedKeys = Object.keys(openSearches).sort((a, b) => {
		if (a === "EMPTY") return 1;
		if (b === "EMPTY") return -1;
		return openSearches[a].order - openSearches[b].order;
	});

	const tabs = orderedKeys.map((searchId) => {
		if (!openSearches[searchId]) return null;

		const { filters, state, stats } = openSearches[searchId];

		if (searchId === "EMPTY") {
			return (
				<TabComponent
					id={searchId}
					key={"tab_" + searchId}
					isEmpty={true}
					selectedTab={currentSearchId}
					tabWidth={tabWidth}
					onSelectTab={(event, searchId) => onSelectTab(event, searchId)}
					processing={openSearches[searchId].processing}
					blockSearch={orderedKeys.length > tabsLimit}
					creatingSearch={blockTabs}
				/>
			);
		}
		const destination =
			filters?.destinationStates__view?.length > 0
				? " - " + filters?.destinationStates__view?.map((state) => state.description).join(", ")
				: filters?.location_destination
				? " - " + filters?.location_destination?.replaceAll?.(", USA", "")
				: "";

		const titleLabel = filters
			? moment(filters?.pickup_ds__after).format("MMM DD") + " | " + (filters?.truck__view?.label ?? filters?.truck)
			: "Loading...";
		const subtitleLabel = filters ? filters?.location_origin?.replaceAll?.(", USA", "") + destination : undefined;
		const counters = {
			new: stats?.new ?? 0,
			detailsRequests: stats?.detailsRequests,
			detailsErrors: stats?.detailsErrors,
			isVisible: stats?.isVisible,
			isHidden: stats?.isHidden,
			refreshRate: stats?.refreshRate,
			availabilityRate: stats?.availabilityRate,
		};

		return (
			<TabComponent
				key={"tab_" + searchId}
				id={searchId}
				selectedTab={currentSearchId}
				state={state}
				isAuthenticated={isAuthenticated}
				flicker={flicker}
				withAnimation={withAnimation}
				counters={counters}
				title={titleLabel}
				subtitle={subtitleLabel}
				tabWidth={tabWidth}
				processing={openSearches[searchId].processing}
				onSelectTab={(event, searchId) => onSelectTab(event, searchId)}
				onCloseTab={(event, searchId) => onCloseTab(event, searchId)}
				creatingSearch={openSearches["EMPTY"].processing || blockTabs}
			/>
		);
	});

	return (
		<div className="flex w-full h-54 flex-row bg-grey-200 pt-4 px-4 overflow-scroll scrollbar-hide items-end ">
			{tabs}
			<SearchVersionSwitch minWidth={tabWidth / 2} key="searchV3_switch" />
		</div>
	);
}

export default SearchTabs;
