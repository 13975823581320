import { useEffect, useState, useMemo } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import _ from "@lodash";
import { Waypoint } from "react-waypoint";

import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Link from "@material-ui/core/Link";

import { createRequest, throwResErrors, createHeaders } from "app/services/requestUtil";
import { openMenuPanel } from "app/store/tools/menuSlice";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { isVisibleForUser } from "app/main/utils/rolesUtils";
import { formatDate } from "app/main/utils/dateUtils";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { createIconBadge } from "app/main/utils/uiUtils";

import SmarthopShowMoreView from "./SmarthopShowMoreView";

import TooltipView from "./views/TooltipView";
import { CellTypes } from "./SmarthopCellTypes";
import { createTooltip } from "app/main/utils/tableUtils";
import ResourceView from "app/main/tools/ResourceView";
import { resourcesKeys } from "app/main/resource/resourcesFiles";
import { CircularProgress, makeStyles, TextField } from "@material-ui/core";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import WarningConfirmDialog from "app/main/common/WarningConfirmDialog";
import { useRef } from "react";

const DEFAULT_ALIGN_RIGHT_TYPES = ["menu", "number", "currency"];
// eslint-disable-next-line
const phoneRegExp = /^(\s*|(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?))?$/;
const phoneReg = new RegExp(phoneRegExp);

const validateEmail = (email) => {
	return email.match(
		// eslint-disable-next-line
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);
};

const useStyles = makeStyles((theme) => ({
	strikethrough: {
		backgroundImage:
			"url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAAXNSR0IArs4c6QAAABJJREFUGFdjDHcO/88ABYw4OQCF+wXU04vWpgAAAABJRU5ErkJggg==')",
		backgroundRepeat: "repeat-x",
		backgroundPosition: "50% 50%",
		backgroundSize: "100% 1px",
		opacity: "0.3",
	},
	"@keyframes flicker": {
		from: {
			opacity: 1,
		},
		to: {
			opacity: 0.2,
		},
	},
	flicker: {
		animationName: "$flicker",
		animationDuration: "1000ms",
		animationIterationCount: "infinite",
		animationDirection: "alternate",
		animationTimingFunction: "ease-in-out",
	},
	sticky: {
		position: "sticky",
		right: 0,
	},
}));

const _DEFAULT_COMPARATOR = (key, current, past, growthBad, absoluteFormatter) => {
	const whenUp = growthBad ? "DECLINE" : "GROWTH";
	const whenDown = growthBad ? "GROWTH" : "DECLINE";
	const currentValue = current?.[key] ?? 0;
	const pastValue = past?.[key] ?? 0;

	return {
		absolute:
			currentValue === 0 || pastValue === 0 || currentValue === pastValue
				? null
				: absoluteFormatter
				? absoluteFormatter(currentValue - pastValue)
				: (currentValue > pastValue ? "+" : "") + parseInt((currentValue - pastValue) * 100) / 100,
		persentange: parseInt(
			pastValue === 0 ? (currentValue === 0 ? 0 : 100) : ((currentValue - pastValue) / pastValue) * 100
		),
		type: currentValue === pastValue ? null : currentValue > pastValue ? whenUp : whenDown,
	};
};

/**
 *
 * Table Menu Component
 *
 */

function SmarthopTableMenu(props) {
	const [moreMenuEl, setMoreMenuEl] = useState(null);
	const [loading, setLoading] = useState([]);

	let pConfig = props.pConfig;
	let pSticky = props.pSticky;
	let clickConfig = props.clickConfig;
	let pIndex = props.pIndex;
	let noBottomBorder = props.noBottomBorder;
	let user = props.user;
	let itemId = props.itemId;
	let item = props.item;
	let variant = props.variant;

	let onMenuItemClick = props.onMenuItemClick;

	const handleMenuItemClick = (itemId, item, config, newTab) => {
		if (config?.button?.simulateLoading) {
			setLoading((loadings) => {
				const l = [...loadings];
				l.push(itemId);
				return l;
			});
			setTimeout(() => setLoading((list) => list.filter((l) => l !== itemId)), 2000);
		}
		if (onMenuItemClick) onMenuItemClick(itemId, item, config, newTab);
		setMoreMenuEl(null);
	};
	const handleMoreMenuClick = (event) => {
		event.stopPropagation();
		setMoreMenuEl(event.target);
	};
	const handleMoreMenuClose = (event) => {
		event.stopPropagation();
		setMoreMenuEl(null);
	};

	const rowMenuClassName = variant === "extra-skinny" ? "h-24 w-28" : variant === "skinny" ? " h-28 w-28 " : "";

	const actionButtonClassName =
		variant === "extra-skinny"
			? "whitespace-nowrap text-11 ml:text-12 h-24 px-8 py-0 mx-2"
			: variant === "skinny"
			? "whitespace-nowrap text-11 ml:text-12 h-24 md:h-28 px-12 py-0 mx-2"
			: "whitespace-nowrap px-12 ml:px-20 mx-2";

	let menuActions = [];
	let menuButtons = [];

	if (!!clickConfig?.table?.addToMenu && isVisibleForUser(clickConfig.auth, user)) {
		menuActions.push(
			<MenuItem
				key={"onclick_menu_action"}
				className="px-20 py-10"
				onClick={(e) => {
					e.stopPropagation();
					handleMenuItemClick(itemId, item, clickConfig);
				}}
			>
				{clickConfig.label ?? "View"}
			</MenuItem>
		);
	}

	pConfig.items?.forEach((menuItemConfig, menuIndx) => {
		let menuItemKey = pIndex + "_menu_" + menuIndx;
		if (!isVisibleForUser(menuItemConfig.auth, user)) {
			return;
		}
		if (menuItemConfig.isVisible && !menuItemConfig.isVisible(item)) {
			return;
		}

		const buttonLabel =
			typeof menuItemConfig?.label === "function" ? menuItemConfig?.label?.(item) : menuItemConfig?.label;
		const buttonConfig =
			typeof menuItemConfig?.button === "function" ? menuItemConfig?.button?.(item) : menuItemConfig?.button;

		if (menuItemConfig.variant === "button") {
			menuButtons.push(
				<Button
					key={menuItemKey + "_menu_" + menuButtons.length}
					className={actionButtonClassName}
					variant={buttonConfig?.variant ?? "contained"}
					color={buttonConfig?.color ?? "secondary"}
					aria-label="Follow"
					onClick={(e) => {
						e.stopPropagation();
						handleMenuItemClick(itemId, item, menuItemConfig);
					}}
				>
					{menuItemConfig.label}
				</Button>
			);
		} else if (menuItemConfig.variant === "iconButton") {
			menuButtons.push(
				<Tooltip key={menuItemKey + "_menu_tooltip_" + menuButtons.length} title={buttonLabel}>
					<IconButton
						key={menuItemKey + "_menu_icon_" + menuButtons.length}
						className={buttonConfig?.classes ?? ""}
						variant="contained"
						color={buttonConfig?.color ?? "secondary"}
						aria-label="Follow"
						onClick={(e) => {
							e.stopPropagation();
							handleMenuItemClick(itemId, item, menuItemConfig);
						}}
					>
						{loading?.includes(itemId) && <CircularProgress className="mb-2 mr-4" size={12} />}
						{!loading?.includes(itemId) && <Icon className="text-20">{buttonConfig?.icon}</Icon>}
					</IconButton>
				</Tooltip>
			);
		} else if (menuItemConfig.variant === "divider") {
			menuActions.push(<Divider key={menuItemKey + "_divider"} />);
		} else {
			menuActions.push(
				<MenuItem
					key={menuItemKey + "_menu_action_" + menuButtons.length}
					className="px-20 py-10"
					onClick={(e) => {
						e.stopPropagation();
						handleMenuItemClick(itemId, item, menuItemConfig);
					}}
				>
					{menuItemConfig.label}
					{menuItemConfig.newTab && (
						<IconButton
							className="ml-20 mr-0 h-20 w-20 p-0 mt-0 mb-0"
							key="close"
							color="inherit"
							onClick={(e) => {
								e.stopPropagation();
								handleMenuItemClick(itemId, item, menuItemConfig, true);
							}}
						>
							<Icon className="text-16 ml:text-20">launch</Icon>
						</IconButton>
					)}
				</MenuItem>
			);
		}
	});

	return (
		<TableCell
			key={pConfig.key + "_" + pIndex}
			padding="none"
			className={"flex-row " + rowMenuClassName}
			scope="row"
			align="right"
			style={{
				position: pSticky?.right >= 0 ? "sticky" : "relative",
				right: pSticky?.right >= 0 ? pSticky.right : undefined,
				backgroundColor: pSticky?.right >= 0 ? pSticky?.backgroundColor : undefined,
				maxWidth: pSticky?.right >= 0 ? pSticky.width : pConfig?.table?.maxWidth,
				minWidth: pSticky?.right >= 0 ? pSticky.width : pConfig?.table?.minWidth,
				width: pSticky?.right >= 0 ? pSticky.width : pConfig?.table?.width,
				...(noBottomBorder ? { borderBottom: "none" } : {}),
			}}
		>
			{!menuActions.length && !menuButtons.length ? (
				<></>
			) : (
				<div className="whitespace-nowrap tracking-wide">
					{menuButtons.length > 0 && <>{menuButtons}</>}
					{menuActions.length > 0 && (
						<>
							<IconButton
								color="inherit"
								aria-label="more"
								aria-owns={!!moreMenuEl ? "chats-more-menu" : null}
								aria-haspopup="true"
								className={rowMenuClassName + " p-6 mr-16"}
								onClick={(e) => handleMoreMenuClick(e)}
							>
								<Icon>more_vert</Icon>
							</IconButton>
							<Menu id="chats-more-menu" anchorEl={moreMenuEl} open={!!moreMenuEl} onClose={handleMoreMenuClose}>
								{menuActions}
							</Menu>
						</>
					)}
				</div>
			)}
		</TableCell>
	);
}

/**
 *
 * Table Cell Component
 *
 */

function SmarthopTableCell(props) {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const [data, setData] = useState(null);

	let pConfig = props.pConfig;
	let isView = props.isView;
	let pIndex = props.pIndex;
	let pSticky = props.pSticky;
	let rowIndex = props.rowIndex;
	let noBottomBorder = props.noBottomBorder;
	let itemId = props.itemId;
	let item = props.item;
	let comparableItem = props.comparableItem;
	let groups = props.groups;
	let variant = props.variant;
	let internalView = props.internalView;
	let multiline = props.multiline;
	let onRowItemClick = props.onRowItemClick;
	let onEnter = props.onEnter;

	useEffect(() => {
		if (!!data || !pConfig.GET) {
			return;
		}

		let inactive = false;
		(async () => {
			try {
				await new Promise((r) => setTimeout(r, rowIndex * 200));
				if (inactive) return;
				const data = await createRequest(item)
					.get(pConfig.GET(item), { headers: createHeaders() })
					.then((res) => res.data)
					.catch((error) => throwResErrors(error));
				if (inactive) return;
				setData(data ?? {});
			} catch (err) {
				if (inactive) return;
				setData({ error: err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong..." });
			}
		})();
		return () => {
			inactive = true;
		};
		// eslint-disable-next-line
	}, [itemId]);

	const [formData, setFormData] = useState({});
	const [processing, setProcessing] = useState(false);
	const [processed, setProcessed] = useState(false);
	const [editConfirmation, setEditConfirmation] = useState(null);
	const [openWarningDialog, setOpenWarningDialog] = useState(null);
	const [pendingUpdate, setPendingUpdate] = useState(null);
	const timer = useRef(null);
	const pendingTimer = useRef(null);

	// useEffect(() => {
	// 	const currentList = store.getState()?.auth?.register?.pendingTableUpdates ?? {};
	// 	const l = { ...currentList };
	// 	l[itemId] = pendingUpdate?.updatedData ?? {};
	// 	dispatch(setPendingTableUpdates({ data: pendingUpdate?.updatedData, id: itemId }));
	// }, [pendingUpdate]);

	useEffect(() => {
		let timeout;
		if (processed) {
			timeout = setTimeout(() => {
				setProcessed(false);
			}, 3000);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [processed]);

	useEffect(() => {
		if (pendingUpdate && processed) {
			if (pendingTimer.current) clearTimeout(pendingTimer.current);
			pendingTimer.current = setTimeout(() => {
				setPendingUpdate(null);
			}, 12000);
		}

		return () => {
			clearTimeout(pendingTimer.current);
		};
	}, [pendingUpdate, processed]);

	const disabled = useMemo(
		() =>
			typeof props?.pConfig?.edit?.disabled === "function"
				? !!props?.pConfig?.edit?.disabled?.(props.item)
				: props?.pConfig?.edit?.disabled ?? false,
		[props?.pConfig?.edit, props.item]
	);

	const editable = useMemo(() => {
		if (!props?.pConfig?.editable) return true;
		return props.pConfig.editable(props.item);
	}, [props.pConfig, props.item]);

	const editEnabled = useMemo(
		() => !!props?.pConfig?.edit?.urlPUT && !disabled && editable,
		[props?.pConfig?.edit?.urlPUT, disabled, editable]
	);

	const params = useMemo(
		() => (!editEnabled ? null : props?.pConfig.edit?.paramsBuilder?.(props.item, props.dataIds)),
		[editEnabled, props]
	);

	const dataToUse = useMemo(() => {
		return { ...(params?.data ?? {}), ...formData };
	}, [params, formData]);

	const mergedPendingData = useMemo(() => {
		return { ...dataToUse, ...(pendingUpdate?.updatedData ?? {}) };
	}, [dataToUse, pendingUpdate]);

	const textFieldError = useMemo(() => {
		const fieldType = props?.pConfig?.edit?.field?.type;
		const fieldValue = mergedPendingData[props?.pConfig?.key];
		const required = props?.pConfig?.edit?.field?.required;
		if (required && !fieldValue) return true;
		if (fieldType !== "email" && fieldType !== "phone") return false;

		if (fieldType === "email" && fieldValue) {
			return !validateEmail(fieldValue);
		} else if (fieldType === "phone" && fieldValue) {
			return !phoneReg.test(fieldValue);
		}
	}, [props?.pConfig, mergedPendingData]);

	useEffect(() => {
		if (pendingUpdate?.updatedData && pendingUpdate?.originalData) {
			clearTimeout(timer.current);
			timer.current = setTimeout(() => {
				return submitData(pendingUpdate.updatedData, pendingUpdate.originalData);
			}, 2000);
		}

		// eslint-disable-next-line
	}, [pendingUpdate]);

	const onAcceptWarnings = () => {
		const update = {
			...openWarningDialog.updatedData,
			ignoreWarnings: true,
		};
		setFormData(update);
		setOpenWarningDialog(null);
		submitData(update, openWarningDialog.originalData);
	};

	const onDeclineWarnings = () => {
		setOpenWarningDialog(null);
		setFormData(openWarningDialog.originalData);
	};

	const editFieldKey = props.pConfig?.edit?.field?.key;

	useEffect(() => {
		if (!editFieldKey || !Object.keys(formData ?? {}).length) return;

		if (
			// If no longer editable we can reset edited form data right away
			!editable ||
			// If still editable we need to wait unit we get reloaded value first
			formData?.[editFieldKey] === params?.data?.[editFieldKey]
		) {
			setFormData({});
		}
	}, [editable, processed, formData, params, editFieldKey]);

	const rowCellClassName =
		(isView || variant === "extra-skinny"
			? "h-34 px-4 ml:px-8 " + (pConfig.type !== "menu" ? " py-2  ml:py-5  " : " py-0 ")
			: variant === "skinny"
			? "h-34 xl:h-40 px-8 ml:px-10 " + (pConfig.type !== "menu" ? " py-2 ml:py-4 " : " py-0 ")
			: " p-4 ml:p-16 ") +
		" " +
		(pConfig.classes?.cell ?? "");

	const typoClassName =
		pConfig.type === "group" && (variant === "extra-skinny" || variant === "skinny")
			? " text-12 ml:text-13 "
			: isView
			? " text-12 "
			: variant === "extra-skinny"
			? " text-11 ml:text-12 "
			: " text-12 ml:text-13 ";

	const align = pConfig?.table?.align;
	const tableCellParams = {
		key: pConfig.key + "_" + pIndex,
		className: rowCellClassName + " flex-row " + (processing ? " pointer-events-none " : ""),
		// Enable to show cursor pointer -> component="th"
		scope: "row",
		style: {
			position: pSticky?.right >= 0 ? "sticky" : "relative",
			backgroundColor: pSticky?.right >= 0 ? pSticky?.backgroundColor : undefined,
			right: pSticky?.right >= 0 ? pSticky.right : undefined,
			maxWidth: pSticky?.right >= 0 ? pSticky.width : pConfig?.table?.maxWidth,
			minWidth: pSticky?.right >= 0 ? pSticky.width : pConfig?.table?.minWidth,
			width: pSticky?.right >= 0 ? pSticky.width : pConfig?.table?.width,
			...(noBottomBorder ? { borderBottom: "none" } : {}),
		},
		align: !!align ? align : align ?? DEFAULT_ALIGN_RIGHT_TYPES.includes(pConfig.type) ? "right" : "left",
		onClick: editEnabled
			? null
			: pConfig.click
			? (event) => {
					const cellText = document.getSelection();
					if (cellText.type === "Range") {
						return event.stopPropagation();
					}
					if (onRowItemClick) onRowItemClick(itemId, item, pConfig.click);
			  }
			: null,
	};

	if (!data && !!pConfig.GET) {
		return (
			<TableCell {...tableCellParams}>
				<Typography className={typoClassName + " text-grey"}>Loading...</Typography>
			</TableCell>
		);
	} else if (!!data?.error) {
		return (
			<TableCell {...tableCellParams}>
				<TooltipView title={data?.error}>
					<Typography className={typoClassName + " text-red"}>Failed to Load</Typography>
				</TooltipView>
			</TableCell>
		);
	}

	let cellItems = pConfig.type === "group" ? pConfig?.items : [pConfig];

	const toCellValueViews = (config, index, item) => {
		let hidden = !!config?.hidden;

		if (config.group && !groups?.[config.group]) {
			return null;
		}
		if ((config.internal && !internalView) || hidden) {
			return null;
		}

		let cellContent;

		let cellValue;
		let cellType = typeof config?.type === "function" ? config?.type(item) : config?.type;
		let cellBuilder = config?.builder;

		const cellMaxLengthStart = config?.maxLengthStart;
		const cellMaxLengthEnd = config?.maxLengthEnd;
		const allCaps = config?.allCaps;
		const collapsibleLength = config?.collapsibleLength;
		const viewConfig = typeof config?.viewConfig === "function" ? config?.viewConfig(item) : config?.viewConfig;
		const valueConfig = config?.valueConfig;

		if (internalView && config.internal) {
			cellType = cellType ?? "text";
			cellBuilder = cellBuilder ?? null;
		}

		if (cellBuilder) {
			cellValue = cellBuilder(item, dispatch, snackbar, data);
		} else {
			let keys = Array.isArray(config.key) ? config.key?.[0]?.split(".") : config.key?.split(".");
			keys?.forEach((key) => {
				cellValue = cellValue ? cellValue[key] : item[key];
			});
			cellValue = Array.isArray(cellValue) ? cellValue.join(",") : cellValue;
		}

		let preCroppedCellVlaue;
		if (
			cellType === "text" &&
			((cellMaxLengthStart > 0 && cellValue?.length > cellMaxLengthStart) ||
				(cellMaxLengthEnd > 0 && cellValue?.length > cellMaxLengthEnd))
		) {
			preCroppedCellVlaue = cellValue;
			cellValue =
				cellMaxLengthStart > 0
					? cellValue.substring(0, cellMaxLengthStart) + "..."
					: "..." + cellValue.substring(cellValue?.length - cellMaxLengthEnd);
		}

		// TODO - Temporary condition while other cellTypes are moved to the cellType dictionary
		if (Object.keys(CellTypes).includes(cellType)) {
			cellContent = CellTypes[cellType]({
				item,
				cellValue,
				variant: isView ? "view" : variant,
				viewConfig,
				typoClassName,
				valueConfig,
			});
		} else {
			if (cellType === "date") {
				cellValue = cellValue ? formatDate(cellValue, null, config.date?.military) : "-";
			} else if (cellType === "bool") {
				cellValue = cellValue === true || cellValue === 1 ? "Yes" : "No";
			}

			if (Array.isArray(cellValue)) {
				cellValue = cellValue.join(", ");
			}
			if (_.isObject(cellValue)) {
				if (cellValue.label) {
					cellValue = cellValue.label;
				} else if (cellValue.value) {
					cellValue = cellValue.value;
				} else if (!cellValue.props) {
					cellValue = JSON.stringify(cellValue);
				}
			}
			if (internalView) {
				cellValue = cellValue + "";
			}
			if (allCaps && cellValue) {
				cellValue = cellValue.toUpperCase();
			}

			let textClassName = pConfig?.table?.noWrap
				? " whitespace-nowrap "
				: multiline && cellValue?.length > 20
				? " min-w-150 break-words whitespace-normal tracking-wide "
				: " tracking-wide ";

			textClassName += pConfig?.table?.classes?.text ?? "";
			if (index > 0) {
				textClassName += " text-grey-600";
			}

			let cellProcessedValue = cellValue || cellValue === 0 || cellValue < 0 ? cellValue : "-";

			if (config.click) {
				cellContent = (
					<Link className={typoClassName + textClassName} color={cellValue ? "inherit" : "textSecondary"}>
						{cellProcessedValue}
					</Link>
				);
			} else if (
				cellProcessedValue &&
				typeof cellProcessedValue === "string" &&
				collapsibleLength > 0 &&
				cellProcessedValue.length > collapsibleLength - 3
			) {
				cellContent = (
					<SmarthopShowMoreView
						textClassName={typoClassName + textClassName}
						text={cellProcessedValue}
						length={collapsibleLength - 3}
					/>
				);
			} else {
				let textVeiw = (
					<Typography
						component={"div"}
						className={typoClassName + textClassName}
						color={cellValue ? "inherit" : "textSecondary"}
					>
						{cellProcessedValue}
					</Typography>
				);

				if (preCroppedCellVlaue?.length > 0) {
					cellContent = <TooltipView title={preCroppedCellVlaue}>{textVeiw}</TooltipView>;
				} else {
					cellContent = textVeiw;
				}
			}
		}

		return cellContent;
	};

	let cellValues = cellItems.map((config, index) => toCellValueViews(config, index, item));
	if (!cellValues[0]) {
		return null;
	}

	const comparationSupported = comparableItem && pConfig.comparison?.supported;
	const comparableCellValues = comparationSupported
		? cellItems.map((config, index) => toCellValueViews(config, index, comparableItem))
		: null;
	let comparatorBuilder = comparationSupported ? pConfig.comparison?.comparator ?? _DEFAULT_COMPARATOR : null;
	const growthBad = !!pConfig.comparison?.growthBad;
	const formatter = pConfig.comparison?.formatter;
	let comapisonSummary = comparatorBuilder?.(pConfig.key, item, comparableItem, growthBad, formatter);

	const comparisonIcon =
		comapisonSummary?.persentange > 0 ? "north" : comapisonSummary?.persentange < 0 ? "south" : null;
	const comparisonColor =
		comapisonSummary?.type === "GROWTH"
			? "text-green-700"
			: comapisonSummary?.type === "DECLINE"
			? "text-red-700"
			: "text-orange-700";

	const onChangeCommitted = async (model) => {
		let originalData = {};
		let updatedData = {};

		setFormData((data) => {
			originalData = data;
			updatedData = { ...data, ...model };
			return updatedData;
		});

		const confrimationView = props?.pConfig?.edit?.confirmation?.(
			{ updatedData, originalItem: props.item },
			// onSubmit
			(processedData) => {
				setEditConfirmation(null);
				submitData(processedData, originalData);
			},
			// onCancel
			() => {
				setEditConfirmation(null);
				setFormData(originalData);
			}
		);

		if (confrimationView) {
			setEditConfirmation(confrimationView);
		} else {
			submitData(updatedData, originalData);
		}
	};

	const submitData = async (updatedData, originalData) => {
		setProcessed(false);
		setProcessing(true);

		let url = props.pConfig.edit.urlPUT;
		Object.keys(params.dataIds).forEach((key) => {
			url = url.replace(":" + key, params.dataIds[key]);
		});

		try {
			const params = { ignoreWarnings: updatedData.ignoreWarnings ?? false };
			if (updatedData) await createRequest().put(url, { data: updatedData }, { headers: createHeaders(), params });
			if (!props?.pConfig?.edit?.suppressSnackbar) {
				showSnackbar(snackbar, "Saved", "success");
			}
			if (props?.pConfig?.edit?.triggerEvent) {
				if (Array.isArray(props?.pConfig?.edit?.triggerEvent)) {
					props?.pConfig?.edit?.triggerEvent?.forEach((event) => {
						dispatch(incrementDataRevision({ event }));
					});
				} else {
					dispatch(incrementDataRevision({ event: props?.pConfig?.edit?.triggerEvent }));
				}
			}
			setProcessed(true);
		} catch (errors) {
			const warnings = errors?.response?.data?.errors?.[0]?.metadata?.warnings;
			if (!warnings?.length) {
				let response = errors?.response;
				let message =
					response?.data?.errors?.[0]?.message ??
					(errors?.message ? "Unexpected Error: " + errors?.message : "Something went wrong, please try again later");

				showSnackbar(snackbar, message, "error");
				setFormData(originalData);
				setPendingUpdate(null);
			} else {
				setOpenWarningDialog({
					warnings: warnings,
					originalData: originalData,
					updatedData: updatedData,
				});
			}
		}
		setProcessing(false);
	};

	if (editEnabled) {
		// Changing form key to alway generate new component to avoid onChangeCommitted
		// being called if value changed after table is refreshed
		const formKey = "edit-" + editFieldKey + "_" + dataToUse?.[editFieldKey] + "-" + props.item[editFieldKey];
		const fieldType = props?.pConfig?.edit?.field?.type;
		const containerClasses =
			(props.pConfig.edit?.field?.classes?.cell?.includes("min-w-") ? "" : " min-w-104 ") +
			(props.pConfig.edit?.field?.classes?.cell ?? "");
		cellValues = [
			<>
				<WarningConfirmDialog
					open={!!openWarningDialog}
					warnings={openWarningDialog?.warnings}
					onAccept={onAcceptWarnings}
					onClose={onDeclineWarnings}
				/>
				{["text", "phone", "email"].includes(fieldType) && (
					<TextField
						error={!!textFieldError}
						className={props.pConfig.edit?.field?.classes?.cell ? props.pConfig.edit?.field?.classes?.cell : "w-10/12"}
						variant="standard"
						value={pendingUpdate?.updatedData?.[editFieldKey] ?? dataToUse?.[editFieldKey]}
						onChange={(event) => {
							const originalData = {
								[editFieldKey]: dataToUse?.[editFieldKey],
							};
							const updatedData = {
								[editFieldKey]: event.target.value,
							};
							setPendingUpdate({ originalData, updatedData });
						}}
					/>
				)}
				{!["text", "phone", "email"].includes(fieldType) && (
					<SmarthopFormView
						key={formKey}
						content={{
							form: { noErrorMessage: true },
							items: [
								{
									...props.pConfig.edit.field,
									label: "",
									field: {
										...(props.pConfig.edit?.field?.field ?? {}),
										noErrorMessage: true,
										variant: "row",
										classes: { root: "border-5 mt-2 flex " + containerClasses },
									},
								},
							],
						}}
						trackChangedFields={["ALL"]}
						onChangeCommitted={onChangeCommitted}
						data={dataToUse}
						mode={"EDIT"}
						dataIds={params.dataIds}
					/>
				)}
				{editConfirmation}
				{(processing || processed) && (
					<div className={"flex absolute items-center -ml-24 ml:-ml-32 z-50 " + containerClasses}>
						<div className="flex bg-white p-4 ml:p-8 rounded-full border-1">
							{processing ? (
								<CircularProgress size={12} />
							) : (
								<Icon className={"text-13 ml:text-14 text-green"}>done</Icon>
							)}
						</div>
					</div>
				)}
			</>,
		];
		if (props?.pConfig?.builder) {
			cellValues.unshift(props?.pConfig?.builder(item, dispatch, snackbar, data));
		}
	}

	const renderCellContentViews = (cellValues) => {
		return cellValues.length > 1 ? (
			<div className={`flex ${editEnabled ? "flex-row" : "flex-col"}`}>
				{cellValues.map((item, index) => (
					<div
						key={index + "_cell"}
						className={
							"flex flex-row items-center" +
							(index > 0 && (variant === "skinny" || variant === "extra-skinny") ? " -mt-3 " : "")
						}
					>
						{item}
					</div>
				))}
			</div>
		) : (
			cellValues[0]
		);
	};

	// Using justify-content because float(right or left) is not going to work along with flex
	const cellContentAlignment =
		pConfig?.table?.align === "center"
			? "justify-center"
			: pConfig?.table?.align === "left"
			? "justify-start"
			: pConfig?.table?.align === "right" || DEFAULT_ALIGN_RIGHT_TYPES.includes(pConfig.type)
			? "justify-end"
			: "";

	return (
		<TableCell {...tableCellParams}>
			<div className={`flex flex-row items-center ${cellContentAlignment}`}>
				{!comparableCellValues ? (
					renderCellContentViews(cellValues)
				) : (
					<div className={`flex flex-row items-center whitespace-nowrap`}>
						<div className={`flex flex-col items-center my-8`}>
							{renderCellContentViews(cellValues)}
							<Divider className="w-20 my-8" />
							{comparableCellValues && renderCellContentViews(comparableCellValues)}
						</div>
						<Icon className={"text-14 ml:text-15 ml-12 " + comparisonColor}>{comparisonIcon}</Icon>
						<div className={`flex flex-col items-center `}>
							<Typography
								component={"div"}
								color="inherit"
								className={"pt-3 text-12 ml:text-13 whitespace-nowrap font-mono font-medium  " + comparisonColor}
							>
								{(comapisonSummary.persentange > 0 ? "+" : "") + comapisonSummary.persentange + "%"}
							</Typography>
							{comapisonSummary.absolute && (
								<Typography
									component={"div"}
									color="inherit"
									className={"pb-3 font-normal text-11 ml:text-12 whitespace-nowrap  " + comparisonColor}
								>
									{"(" + comapisonSummary.absolute + ")"}
								</Typography>
							)}
						</div>
					</div>
				)}
			</div>
			{pIndex === 0 && <Waypoint onEnter={() => onEnter(rowIndex)} />}
		</TableCell>
	);
}

/**
 *
 * Table Row Component
 *
 */

function SmarthopTableRowItem(props) {
	const classes = useStyles();
	let user = props.user;
	let isView = props.isView;
	let smallScreen = props.smallScreen;
	let noBottomBorder = props.noBottomBorder;
	let rowIndex = props.rowIndex;
	let itemId = props.itemId;
	let item = props.item;
	let stickyConfs = props.stickyConfs;
	let highlighted = props.highlighted;
	let highlightedOpenPannel = props.highlightedOpenPannel;
	let content = props.content;
	let groups = props.groups;
	let variant = props.variant;
	let internalView = props.internalView;
	let selectionDisabled = props.selectionDisabled ?? false;
	let selectionVisible = props.selectionVisible ?? true;
	let selectedItems = props.selectedItems;
	let multiselect = props.multiselect;
	let selected = props.selected;
	const crossedOut = !!props.crossedOut;

	let onRowItemClick = props.onRowItemClick;
	let handleSelectItem = props.handleSelectItem;

	const rowClassName = isView
		? " h-48 "
		: variant === "extra-skinny"
		? "h-34 "
		: variant === "skinny"
		? " h-52 "
		: " h-72 ";

	const layout = content?.table?.layout;
	return useMemo(() => {
		return (
			<TableRow
				className={
					(highlightedOpenPannel ? "bg-blue-100 " : highlighted && !crossedOut ? "bg-orange-50 " : "bg-transparent ") +
					rowClassName +
					(content.click && isVisibleForUser(content.click.auth, user) ? " cursor-pointer " : "") +
					(crossedOut ? classes.strikethrough : "")
				}
				hover
				role="checkbox"
				aria-checked={false}
				tabIndex={-1}
				selected={multiselect && selected}
				onClick={
					content.click && isVisibleForUser(content.click.auth, user)
						? (event) => {
								const cellText = document.getSelection();
								if (cellText.type === "Range") {
									return event.stopPropagation();
								}
								if (onRowItemClick) onRowItemClick(itemId, item, content.click);
						  }
						: null
				}
			>
				{multiselect && selectionVisible && (
					<TableCell padding="checkbox" style={{ ...(noBottomBorder ? { borderBottom: "none" } : {}) }}>
						<div className="flex flex-row w-full justify-end items-center">
							<Checkbox
								color="primary"
								checked={selected}
								disabled={selectionDisabled}
								onClick={(event) => {
									event.stopPropagation();
								}}
								onChange={(event) => {
									if (multiselect && !selectionDisabled) handleSelectItem(event, itemId, item);
								}}
							/>
							<Divider
								orientation="vertical"
								className={
									variant === "extra-skinny"
										? " h-16 ml-6 mr-4 "
										: variant === "skinny"
										? " h-32 mx-2 ml:mx-4 "
										: " h-40 mx-2 ml:mx-4 "
								}
							/>
						</div>
					</TableCell>
				)}

				{item.type === "separator" && (
					<TableCell
						colSpan={content.items.length}
						style={{
							...(noBottomBorder ? { borderBottom: "none" } : {}),
							...(!item.description ? { pointerEvents: "none" } : {}),
						}}
						className={
							variant === "extra-skinny" ? "py-6 ml:py-10" : variant === "skinny" ? "py-8 ml:py-12" : " py-10 ml:py-14 "
						}
						key={"separator_" + item.label}
					>
						<div className="flex flex-row items-center">
							{item.showInProgress && (
								<div className={`text-green pt-4 ${classes.flicker}`}>
									<Icon className={"ml-10 ml:ml-2 text-18"}>cached</Icon>
								</div>
							)}
							{item.icon && createIconBadge(item.icon, "extra-skiny")}
							<div className="flex flex-row items-start">
								<Typography
									className={`${item.classes?.text ? item.classes.text : "text-12 ml:text-13 font-medium"}  ml-2`}
								>
									{item.label}
								</Typography>
								{item.description &&
									createTooltip(
										<Icon className="flex text-11 ml:text-12 text-blue-500 ml-2 flex-start">info</Icon>,
										item.description
									)}
							</div>
						</div>
					</TableCell>
				)}

				{item.type !== "separator" &&
					content.items?.map((_pConfig, pIndex) => {
						const pSticky = stickyConfs?.[pIndex];

						const adjustedSizes = _pConfig.table ? { ..._pConfig.table } : undefined;
						if (adjustedSizes?.width > 0 && smallScreen) {
							adjustedSizes.width = parseInt(adjustedSizes?.width * 0.8);
						}
						if (adjustedSizes?.minWidth > 0 && smallScreen) {
							adjustedSizes.minWidth = parseInt(adjustedSizes?.minWidth * 0.8);
						}
						if (adjustedSizes?.maxWidth > 0 && smallScreen) {
							adjustedSizes.maxWidth = parseInt(adjustedSizes?.maxWidth * 0.8);
						}
						const pConfig = { ..._pConfig, table: adjustedSizes };

						if (pConfig.group && !groups?.[pConfig.group]) {
							return null;
						}
						if (layout?.length > 0 && !layout.includes(pConfig.key) && pConfig.key !== "menu") {
							return null;
						}

						const key = pConfig.key + "_" + pIndex;
						if (pConfig.type === "separator") {
							return (
								<TableCell
									style={{
										position: pSticky?.right >= 0 ? "sticky" : "relative",
										backgroundColor: pSticky?.right >= 0 ? pSticky?.backgroundColor : undefined,
										right: pSticky?.right >= 0 ? pSticky.right : undefined,
										maxWidth: pSticky?.right >= 0 ? pSticky.width : pConfig?.table?.maxWidth,
										minWidth: pSticky?.right >= 0 ? pSticky.width : pConfig?.table?.minWidth,
										width: pSticky?.right >= 0 ? pSticky.width : pConfig?.table?.width,
									}}
									key={key}
									padding="none"
									align="center"
								>
									<div className="flex flex-row w-full justify-end">
										<Divider
											orientation="vertical"
											className={
												variant === "extra-skinny"
													? " h-16 ml-6 mr-4 "
													: variant === "skinny"
													? " h-32 mx-2 ml:mx-4  "
													: " h-40 mx-2 ml:mx-4  "
											}
										/>
									</div>
								</TableCell>
							);
						} else if (pConfig.type === "menu") {
							return (
								<SmarthopTableMenu
									key={key}
									{...props}
									pConfig={pConfig}
									pIndex={pIndex}
									pSticky={pSticky}
									clickConfig={content.click}
									noBottomBorder={noBottomBorder}
								/>
							);
						} else {
							return (
								<SmarthopTableCell
									key={key}
									{...props}
									pConfig={pConfig}
									pIndex={pIndex}
									pSticky={pSticky}
									noBottomBorder={noBottomBorder}
								/>
							);
						}
					})}
			</TableRow>
		);
		// eslint-disable-next-line
	}, [rowIndex, itemId, item, internalView, selected, selectedItems, groups, highlighted, smallScreen]);
}

/**
 *
 * Smarthop Table Component
 *
 */

function SmarthopTableView(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const snackbar = useSnackbar();

	const content = props.content;
	const isView = props.isView;
	const contentIdKey = props.contentIdKey;
	const data = props.data;
	const smallScreen = props.smallScreen;
	const dataIds = props.dataIds;
	const sorting = props.sorting;
	const activeSort = props.activeSort;
	const groups = props.groups;
	const selectedItems = props.selectedItems;
	const multiselect = props.multiselect;
	const disableHeaderSelectAll = props.disableHeaderSelectAll;
	const onLoadMore = props.onLoadMore;
	const showSummary = props.showSummary;

	const multiline = content.table?.multiline;
	const variant = content.table?.variant;
	const theme = content.table?.theme;
	const orderBy = content.table?.orderBy;
	const noHeader = content.table?.noHeader;
	const noBottomBorder = content.table?.noBottomBorder;

	const onSelectItems = props.onSelectItems;
	const onOrderChange = props.onOrderChange;
	const onCellChange = props.onCellChange;
	const isSelectionAllowed = props.isSelectionAllowed;

	const user = useSelector(({ auth }) => auth.user);
	const highlightedOpenPannelId = useSelector(({ tools }) => tools.menu.highlightId ?? null);

	const [moreMenuEl, setMoreMenuEl] = useState(null);
	const [screenHeight, setScreenHeight] = useState(parseInt(window.innerHeight / 10) * 10);

	const internalView = content?.internalView;
	const isHighLighted = content?.isHighlighted;
	const isCrossed = content?.isCrossed;

	useEffect(() => {
		function handleResize() {
			let innerHeight = window.innerHeight;
			innerHeight = parseInt(innerHeight / 10) * 10;
			if (innerHeight !== screenHeight) setScreenHeight(innerHeight);
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	const possibleSelectedIds = useMemo(() => {
		let canSelectedIds = [];
		data?.items?.forEach((item) => {
			let itemId = item[contentIdKey];
			let isAllowed = isSelectionAllowed ? !!isSelectionAllowed(item) : true;
			if (isAllowed) canSelectedIds.push(itemId);
		});
		return canSelectedIds;
		// eslint-disable-next-line
	}, [data?.items]);

	const allSelected = useMemo(() => {
		const notSelected = possibleSelectedIds.filter((id) => !selectedItems?.includes(id));
		return notSelected.length === 0 && selectedItems?.length > 0 && possibleSelectedIds.length > 0;
		// eslint-disable-next-line
	}, [selectedItems, possibleSelectedIds]);

	const someSelected = useMemo(() => {
		const selectedIds = selectedItems?.filter((id) => possibleSelectedIds?.includes(id));
		return selectedIds?.length > 0 && !allSelected;
		// eslint-disable-next-line
	}, [selectedItems, possibleSelectedIds, allSelected]);

	const handleSelectAllItems = (event) => {
		if (!allSelected && someSelected) {
			if (onSelectItems) onSelectItems("REMOVE", possibleSelectedIds);
		} else if (event.target.checked) {
			if (onSelectItems) onSelectItems("ADD", possibleSelectedIds);
		} else {
			if (onSelectItems) onSelectItems("REMOVE", possibleSelectedIds);
		}
	};

	const handleSelectItem = (event, itemId, item) => {
		if (event.target.checked) {
			if (onSelectItems) onSelectItems("ADD", [itemId], item);
		} else {
			if (onSelectItems) onSelectItems("REMOVE", [itemId], item);
		}
	};

	const handleMenuItemClick = (itemId, item, config, newTab) => {
		handleAction(itemId, item, config, newTab);
	};

	const handleRowItemClick = (itemId, item, config) => {
		handleAction(itemId, item, config, false);
	};

	const handleAction = (itemId, item, config, newTab) => {
		if (config?.redirectURL || config?.redirectURLBuilder?.(item, dataIds)) {
			let url = config?.redirectURLBuilder ? config?.redirectURLBuilder(item, dataIds) : config?.redirectURL;
			if (newTab) {
				const win = window.open(url, "_blank");
				win.focus();
			} else {
				history.push(url);
			}
		} else if (config?.dialog || config?.dialogBuilder) {
			let dialog = config?.dialogBuilder
				? config?.dialogBuilder(item, dataIds)
				: {
						viewId: config?.dialog?.viewId,
						formId: config?.dialog?.formId,
						formInstance: config?.dialog?.formInstance,
						mode: config?.dialog?.mode,
						dataIds: { ...dataIds, id: itemId },
				  };
			dispatch(openLoadedFormDialog(dialog));
		} else if (config?.dispatchAction) {
			config?.dispatchAction(item, dataIds, dispatch, history, snackbar);
		} else if (config?.openMenuPanel) {
			let menu = config?.openMenuPanel(item, dataIds);
			dispatch(openMenuPanel(menu));
		}
	};

	// close menu if data changed
	useEffect(() => {
		setMoreMenuEl(null);
	}, [data]);

	const handleOnSortRequest = (property) => (event) => {
		if (onOrderChange) onOrderChange(property);
	};

	const handleLoadMore = (index) => {
		if (index === data.items.length - 10) {
			if (onLoadMore) onLoadMore(data.items.length);
		}
	};

	const headerCellClassName =
		(variant === "extra-skinny"
			? "h-32 py-4 ml:py-8 px-4 ml:px-8"
			: variant === "skinny"
			? "h-40 py-4 ml:py-8 px-8 ml:px-12"
			: " p-10 ml:p-14 ") +
		(theme === "dark"
			? " bg-primary text-white "
			: theme === "light"
			? " bg-white text-primary "
			: theme === "grey"
			? " bg-grey-200 text-primary "
			: "");

	const headerRowClassName =
		(variant === "extra-skinny" ? "h-20 sm:h-32" : variant === "skinny" ? " h-20 sm:h-32 " : " h-48 sm:h-64 ") +
		(theme === "dark" ? " " : "");

	const isHidden = (config) => {
		let hidden = !!config?.hidden;
		if ((config.internal && !internalView) || hidden) {
			return true;
		}
		if (config.group && !groups?.[config.group]) {
			return true;
		}
		if (config.type === "menu") {
			let hasVisible =
				config.items?.find((item) => isVisibleForUser(item.auth, user)) ||
				(content.click?.table?.addToMenu && isVisibleForUser(content.click?.auth, user));
			if (!hasVisible) return true;
		}
	};

	let firstVisibleIndex = -1;
	let lastVisibleIndex = -1;
	content.items.forEach((config, indx) => {
		if (isHidden(config)) {
			return null;
		}

		if (firstVisibleIndex === -1) {
			firstVisibleIndex = indx;
		} else {
			lastVisibleIndex = indx;
		}
	});

	let stickyConfs = {};
	let stickyKeys = {};
	let totalRight = 0;
	for (let i = content.items?.length - 1; i >= 0; i--) {
		const item = content.items[i];
		if (item?.table?.sticky && !isHidden(item)) {
			let sticky = {
				width: item?.table?.width ?? item?.table?.minWidth ?? 120,
				right: totalRight,
				backgroundColor: isView ? "#FFFFFF" : "#F6F7F9",
			};
			if (smallScreen && sticky.width > 0) {
				sticky.width = parseInt(sticky.width * 0.8);
			}
			totalRight += sticky.width;
			stickyConfs[i] = sticky;
			stickyKeys[i] = item.key;
		}
	}

	let headerColumns = content.items.map((config, indx) => {
		if (isHidden(config)) {
			return null;
		}

		const pSticky = stickyConfs[indx];
		const cellStyle = pSticky
			? {
					position: "sticky",
					backgroundColor: pSticky?.backgroundColor,
					right: pSticky?.right,
					maxWidth: pSticky?.width,
					minWidth: pSticky?.width,
					width: pSticky?.width,
			  }
			: {};

		if (config.type === "separator") {
			return <TableCell style={cellStyle} key={"separator" + indx} padding="none" align="center"></TableCell>;
		}

		const layout = content?.table?.layout;
		if (layout?.length > 0 && !layout.includes(config.key) && config.key !== "menu") {
			return null;
		}

		const oneLabel = (key, label, description = null, table) => {
			const topLabel = Array.isArray(label) ? label[0] : label;
			const bottomLabel = Array.isArray(label) ? label[1] : null;

			let headerLabel = (
				<div className="flex flex-col">
					<Typography
						component={"div"}
						color={!!theme ? undefined : "primary"}
						className={
							"font-semibold tracking-wide whitespace-nowrap " +
							(!!bottomLabel ? " -mt-5 " : "") +
							(isView ? " text-12 " : variant === "extra-skinny" ? " text-11 ml:text-12 " : "")
						}
					>
						{topLabel}
					</Typography>
					{!!bottomLabel && (
						<Typography
							component={"div"}
							color={!!theme ? undefined : "primary"}
							className={
								"font-semibold tracking-wide whitespace-nowrap -mt-6 -mb-5 " +
								(isView ? " text-12 " : variant === "extra-skinny" ? " text-11 ml:text-12 " : "")
							}
						>
							{bottomLabel}
						</Typography>
					)}
				</div>
			);

			if (description) {
				headerLabel = (
					<div
						className={
							"flex flex-row space-x-1 items-center " + (table?.align === "right" ? " justify-end " : " justify-start ")
						}
					>
						{headerLabel}
						{createTooltip(<Icon className="flex text-11 ml:text-12 text-blue-500 -mt-3">info</Icon>, description)}
					</div>
				);
			}

			if (resourcesKeys[props?.segmentKey + "_" + key]) {
				const infomation = resourcesKeys[props?.segmentKey + "_" + key];
				headerLabel = (
					<div className="flex flex-row space-x-1">
						{headerLabel}
						<ResourceView
							type={"icon"}
							description={infomation?.description}
							link={infomation?.link}
							classes={{ icon: " flex flex-row space-x-1 text-blue mt-0 ml:mt-1", iconSize: "text-11 ml:text-12" }}
						/>
					</div>
				);
			}

			if (orderBy && orderBy.includes(key)) {
				const defaultSort = sorting[key] ? sorting[key].replace("default-", "") : "asc";
				headerLabel = (
					<TableSortLabel
						active={activeSort === key}
						direction={defaultSort}
						onClick={handleOnSortRequest(key)}
						className="font-semibold tracking-wide"
						classes={{
							icon:
								variant === "extra-skinny"
									? config?.table?.align === "right" || DEFAULT_ALIGN_RIGHT_TYPES.includes(config.type)
										? " mr-1 text-14 ml:text-16 "
										: "-ml-1 text-14 ml:text-16"
									: "text-14 ml:text-16",
						}}
					>
						{headerLabel}
					</TableSortLabel>
				);
			}

			return headerLabel;
		};

		let labelContent;
		if (Array.isArray(config.key)) {
			labelContent = (
				<div className="flex flex-row space-x-4">
					{config.label.map((l, index) => {
						return (
							<span key={l + index}>{oneLabel(config.key[index], l, config?.description?.[index], config.table)}</span>
						);
					})}
				</div>
			);
		} else {
			labelContent = oneLabel(config.key, config.label, config.description, config.table);
		}

		const headerThemeClassName = !!theme
			? indx === firstVisibleIndex
				? " rounded-l-lg "
				: lastVisibleIndex === indx
				? " rounded-r-lg "
				: ""
			: "";

		return (
			<TableCell
				className={"cursor-auto " + headerCellClassName + " " + headerThemeClassName}
				style={cellStyle}
				key={config.key + "_" + indx}
				align={
					!!config?.table?.align
						? config?.table?.align
						: DEFAULT_ALIGN_RIGHT_TYPES.includes(config.type)
						? "right"
						: "left"
				}
				padding={config?.table?.padding ?? config.type === "menu" ? "default" : undefined}
				sortDirection={activeSort === config.key ? sorting[config.key] : false}
			>
				{labelContent}
			</TableCell>
		);
	});

	const leftSummaryOffset = content.summary?.offset?.left ?? 0;
	const rightSummaryOffset = content.summary?.offset?.right ?? 0;
	const summaryColumnsSize = headerColumns.length - leftSummaryOffset - rightSummaryOffset;
	const summaryViewBuilder = content.summary?.viewBuilder;
	const summaryRowClasses = content.summary?.classes?.root ?? "";

	let rows = data?.items?.map((item, rowIndex) => {
		let itemId = item[contentIdKey] ?? rowIndex;
		let selected = selectedItems?.indexOf(itemId) !== -1;
		let comparableItem = data?.comparedEntities?.[itemId];
		let highlightedOpenPannel = highlightedOpenPannelId === itemId ?? null;
		let highlighted = isHighLighted ? isHighLighted(item, dataIds) : null;
		let selectionDisabled = isSelectionAllowed ? !isSelectionAllowed(item) : false;
		let crossedOut = isCrossed ? isCrossed(item, dataIds) : null;

		return [
			<SmarthopTableRowItem
				key={itemId + "_id"}
				isView={isView}
				user={user}
				smallScreen={smallScreen}
				multiline={multiline}
				highlighted={highlighted}
				crossedOut={crossedOut}
				highlightedOpenPannel={highlightedOpenPannel}
				itemId={itemId}
				dataIds={dataIds}
				content={content}
				stickyConfs={stickyConfs}
				groups={groups}
				item={item}
				comparableItem={comparableItem}
				rowIndex={rowIndex}
				noBottomBorder={noBottomBorder && rowIndex === data.items.length - 1}
				variant={variant}
				moreMenuEl={moreMenuEl}
				internalView={internalView}
				totalSize={data.items.length}
				multiselect={multiselect}
				selectedItems={selectedItems ?? []}
				selectionDisabled={selectionDisabled}
				selected={selected}
				handleSelectItem={handleSelectItem}
				onCellChange={onCellChange}
				onMenuItemClick={handleMenuItemClick}
				onRowItemClick={handleRowItemClick}
				onEnter={handleLoadMore}
			/>,
			showSummary ? (
				<TableRow key={itemId + "_summary_id"} className={summaryRowClasses}>
					{leftSummaryOffset > 0 && <TableCell colSpan={leftSummaryOffset}></TableCell>}
					{summaryColumnsSize > 0 && (
						<TableCell colSpan={summaryColumnsSize} className="bg-grey-200 m-0 p-0">
							{summaryViewBuilder(item, comparableItem, data.period, data.comparablePeriod)}
						</TableCell>
					)}
					{rightSummaryOffset > 0 && <TableCell colSpan={rightSummaryOffset}></TableCell>}
				</TableRow>
			) : null,
		];
	});

	return (
		<div className={"flex h-full w-full overflow-y-scroll"}>
			<div style={{ width: "calc(100% - 8px)", marginLeft: "4px" }}>
				<Table stickyHeader className="w-full" aria-labelledby="tableTitle">
					{!noHeader && (
						<TableHead>
							<TableRow className={headerRowClassName}>
								{multiselect && (
									<TableCell padding="checkbox">
										<Checkbox
											name="all"
											color="primary"
											indeterminate={someSelected}
											checked={allSelected}
											disabled={disableHeaderSelectAll}
											onChange={handleSelectAllItems}
											onClick={(event) => {
												event.stopPropagation();
											}}
										/>
									</TableCell>
								)}
								{headerColumns}
							</TableRow>
						</TableHead>
					)}
					<TableBody>{rows}</TableBody>
				</Table>
			</div>
		</div>
	);
}

export default SmarthopTableView;
