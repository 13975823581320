import { Link, makeStyles } from "@material-ui/core";
import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { isEnabled } from "app/services/featureStorageService";
import { openFormDialog } from "app/store/tools/formDialogSlice";

const useStyles = makeStyles((theme) => ({
	numberCircleActive: {
		display: "flex",
		width: "fit-content",
		minWidth: "4rem",
		padding: "0.5rem",
		alignItems: "center",
		justifyContent: "center",
		aspectRatio: "1 / 1",
		borderRadius: "50%",
		border: "2px solid white",
		color: "white",
		backgroundColor: "#ef6631",
		marginRight: 16,
	},
}));

const WHITE_LISTED_PATHS = ["/signup", "/setup"];

function InReviewBlocker({ nativeView }) {
	const enabled = useMemo(() => isEnabled("NEW_ONBOARDING_FLOW_V3"), []);
	const linksEnabled = useMemo(() => isEnabled("NEW_ONBOARDING_FLOW_V3_LINKS"), []);

	const classes = useStyles();
	const location = useLocation();
	const dispatch = useDispatch();

	const { pathname } = location;
	const user = useSelector(({ auth }) => auth?.user);
	const inReview = useMemo(() => user?.onboardingStatus === "IN_REVIEW", [user]);
	const hidden = useMemo(
		() => !enabled || !inReview || WHITE_LISTED_PATHS.includes(pathname) || (window?.ReactNativeWebView && !nativeView),
		[enabled, inReview, pathname, nativeView]
	);

	if (hidden) {
		return null;
	}

	const containerClass = window?.ReactNativeWebView
		? ""
		: "fixed h-screen w-screen left-0 top-0 bg-white z-50 overflow-hidden";
	const contentClass = window?.ReactNativeWebView ? "mt-32" : "relative w-4/5 lg:w-2/5 bg-white opacity-100";

	return (
		<div className={containerClass} style={window?.ReactNativeWebView ? {} : { background: "rgba(0, 0, 0, 0.5)" }}>
			<div
				className={"flex flex-col content-center justify-center items-center " + contentClass}
				style={
					window?.ReactNativeWebView
						? {}
						: { left: "50%", transform: "translate(-50%, -50%)", top: "50%", height: "300px" }
				}
			>
				<div className={`flex flex-col content-center justify-center ${!linksEnabled ? " items-center  " : ""}`}>
					<h2>You're Almost Ready to Go!</h2>
					{linksEnabled && (
						<>
							<div className="flex flex-row content-center items-center mb-8">
								<div className={classes.numberCircleActive}>1</div>
								<h3 className="mt-8">
									Validate Your MC/DOT Number{" "}
									<Link
										onClick={() =>
											dispatch(openFormDialog({ viewId: "COMPANY_INFO_CONFIRM", dataIds: { carrierId: user.carrier } }))
										}
									>
										here
									</Link>
								</h3>
							</div>
							<div className="flex flex-row content-center items-center mb-8">
								<div className={classes.numberCircleActive}>2</div>
								<h3 className="mt-8">
									Setup Your Account{" "}
									<Link
										href="https://docs.google.com/forms/d/1cDgnh_UGLqqMc04a0xa0tpKBNpGS2ZQgf1S7mLo7HJE/"
										target="_blank"
										onClick={(e) => {
											if (window?.ReactNativeWebView) {
												e.preventDefault();
												window?.ReactNativeWebView?.postMessage(
													JSON.stringify({
														type: "EXTERNAL_LINK",
														url: "https://docs.google.com/forms/d/1cDgnh_UGLqqMc04a0xa0tpKBNpGS2ZQgf1S7mLo7HJE/",
													})
												);
											}
										}}
									>
										here
									</Link>
								</h3>
							</div>
							<div className="flex flex-row">
								<div className={classes.numberCircleActive}>3</div>
								<h3 className="mt-8">
									Complete your{" "}
									<Link
										target="_blank"
										href="https://meetings.hubspot.com/will-elliot/smarthop-onboarding-call"
										onClick={(e) => {
											if (window?.ReactNativeWebView) {
												e.preventDefault();
												window?.ReactNativeWebView?.postMessage(
													JSON.stringify({
														type: "EXTERNAL_LINK",
														url: "https://meetings.hubspot.com/will-elliot/smarthop-onboarding-call",
													})
												);
											}
										}}
									>
										onboarding call
									</Link>
								</h3>
							</div>
							<p className="flex flex-row mt-16">This page will unlock when you finish these tasks</p>
						</>
					)}
					{!linksEnabled && (
						<p className="mt-16 text-16 text-center">This page will unlock when complete your onboarding calls</p>
					)}
				</div>
			</div>
		</div>
	);
}

export default InReviewBlocker;
