import { deleteDriverAxleImport, updateDriverAxleImport } from "app/services/carrierServices";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

const axleDriverImportNew = (loading, setLoading) => ({
	urlGET: "api/axle_import/carriers/:carrierId/drivers",
	idKey: "id",
	listenEvent: "profileRevision",
	segmentKey: "AXLE_DRIVER_IMPORT",
	content: {
		pagination: {
			enabled: false,
			//default: { size: 10 },
			disableRowsPerPage: true,
			resetScroll: true,
		},
		table: {
			variant: "skinny",
		},
		order: {
			defaut: {
				key: "name",
				dir: "asc",
			},
		},
		defaults: {
			initial: {
				filters: {
					import_status: "__IMPORTABLE__",
				},
			},
		},
		/*filters: {
			search: true,
			items: [
				{
					key: "import_status",
					type: "select",
					label: "Import Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "__IMPORTABLE__",
							label: "Imported & Records to import",
						},
						{
							value: "__DELETED__",
							label: "Don't Import",
						},
					],
				},
			],
		},*/
		items: [
			// {
			// 	key: "status",
			// 	type: "text",
			//   table: { width: "15%" },
			// 	builder: (item) => {
			// 		return (
			// 			<div className="flex flex-col">
			// 				{item.name}
			// 				{item.imported ? (
			// 					<span className="text-green">Imported</span>
			// 				) : item.deleted ? (
			// 					<span className="text-red">Deleted</span>
			// 				) : (
			// 					<span className="text-grey">Not Imported</span>
			// 				)}
			// 			</div>
			// 		);
			// 	},
			// },
			{
				table: { width: "20%" },
				key: "name",
				type: (item) => (item.imported ? "driver__view" : "text"),
				label: "Name *",
				builder: (item) => (item.imported ? item?.driver__view : item?.deleted ? item.name : ""),
				editable: (item) => !item.imported && !item.deleted,
				edit: {
					suppressSnackbar: true,
					urlPUT: "api/axle_import/carriers/:carrierId/axle_driver/:axleDriverId",
					triggerEvent: ["profileRevision", "setupDataRevision"],
					field: {
						required: true,
						key: "name",
						type: "text",
						classes: { cell: "min-w-192" },
					},
					paramsBuilder: (item, dataIds) => {
						const data = {
							data: item,
							dataIds: {
								carrierId: dataIds.carrierId,
								axleDriverId: item.id,
							},
						};
						return data;
					},
				},
			},
			{
				key: "email",
				type: "email",
				label: "Email *",
				editable: (item) => !item.imported && !item.deleted,
				edit: {
					suppressSnackbar: true,
					urlPUT: "api/axle_import/carriers/:carrierId/axle_driver/:axleDriverId",
					triggerEvent: ["profileRevision", "setupDataRevision"],
					field: {
						required: true,
						key: "email",
						type: "email",
						field: {
							commitDelay: 4000,
						},
					},
					paramsBuilder: (item, dataIds) => {
						const data = {
							data: item,
							dataIds: {
								carrierId: dataIds.carrierId,
								axleDriverId: item.id,
							},
						};
						return data;
					},
				},
				table: { width: "20%" },
			},
			{
				key: "phone",
				type: "phone",
				label: "Phone",
				editable: (item) => !item.imported && !item.deleted,
				edit: {
					suppressSnackbar: true,
					urlPUT: "api/axle_import/carriers/:carrierId/axle_driver/:axleDriverId",
					triggerEvent: ["profileRevision", "setupDataRevision"],
					field: {
						key: "phone",
						type: "phone",
						field: {
							commitDelay: 4000,
						},
					},
					paramsBuilder: (item, dataIds) => {
						const data = {
							data: item,
							dataIds: {
								carrierId: dataIds.carrierId,
								axleDriverId: item.id,
							},
						};
						return data;
					},
				},
			},
			{
				table: { width: "20%" },
				key: "truck__view",
				label: "Truck",
				type: "truck__view",
				builder: (item) => (!item.imported && !item.deleted ? null : item?.truck__view),
				editable: (item) => !item.imported && !item.deleted,
				edit: {
					suppressSnackbar: true,
					urlPUT: "api/axle_import/carriers/:carrierId/axle_driver/:axleDriverId",
					triggerEvent: ["profileRevision", "setupDataRevision"],
					paramsBuilder: (item, dataIds) => {
						const data = {
							data: item,
							dataIds: {
								carrierId: dataIds.carrierId,
								axleDriverId: item.id,
							},
						};
						return data;
					},
					field: {
						key: "truck",
						type: "autocomplete",
						label: "Truck",
						required: false,
						classes: { cell: "min-w-192" },
						field: {
							noErrorMessage: true,
							classes: {},
							variant: undefined,
						},
						autocomplete: {
							provider: "smarthop",
							url: "api/profile/carriers/:carrierId/trucks",
							preloadDefault: true,
							preloadImmedately: true,
							params: { options_format_show_capacity_type: true, options_format_hide_driver: true },
						},
					},
				},
			},
			{
				key: "import_status",
				type: "text",
				label: "Import Status",
				builder: (item) => {
					return item.imported ? (
						<span className="text-green">Imported</span>
					) : item.deleted ? (
						<span className="text-red">Skipped</span>
					) : (
						<span className="text-grey">To be Imported</span>
					);
				},
				//table: { width: "20%" },
			},
			{
				type: "menu",
				table: { width: "10%" },
				items: [
					{
						key: "delete",
						label: "Don't Import",
						variant: "iconButton",
						button: {
							icon: "delete",
							color: "primary",
							simulateLoading: true,
						},
						isVisible: (item) => !item.deleted && !item.imported,
						dispatchAction: (item, dataIds, dispatch, history, snackbar) => {
							deleteDriverAxleImport(dataIds.carrierId, item.id).then(() => {
								dispatch(incrementDataRevision({ event: "profileRevision" }));
								dispatch(incrementDataRevision({ event: "setupDataRevision" }));
							});
						},
					},
					{
						key: "add",
						label: "Add",
						variant: "iconButton",
						button: {
							icon: "add",
							color: "primary",
							simulateLoading: true,
						},
						isVisible: (item) => !!item.deleted && !item.imported,
						dispatchAction: (item, dataIds, dispatch, history, snackbar) => {
							updateDriverAxleImport(dataIds.carrierId, item.id, { deleted: false }).then(() => {
								dispatch(incrementDataRevision({ event: "profileRevision" }));
								dispatch(incrementDataRevision({ event: "setupDataRevision" }));
							});
						},
					},
				],
			},
		],
	},
});

export default axleDriverImportNew;
