import axios from "axios";
import { Typography, makeStyles, Link, IconButton, Icon, Select, MenuItem } from "@material-ui/core";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { global } from "app/services/requestUtil";
import * as fileService from "app/services/fileServices";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { fetchActions } from "app/store/actions/actionsUserSlice";
import FilesIconAnalize from "./FilesIconAnalize";
import WarningConfirmDialog from "app/main/common/WarningConfirmDialog";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import { ADDITIONAL_FILES_TYPES_CONVERTION } from "app/main/utils/tripsUtils";
import { DisplaySection } from "app/main/utils/uiUtils";

const STATUS = {
	ERROR: {
		label: "Failed to analyze file.",
		icon: { value: "help_outline", style: "text-red" },
	},
	PROGRESS: { label: "Analyzing..." },
};

const useStyles = makeStyles((theme) => {
	return {
		"@keyframes flicker": { from: { opacity: 1 }, to: { opacity: 0.2 } },
		flicker: {
			animationName: "$flicker",
			animationDuration: "1000ms",
			animationIterationCount: "infinite",
			animationDirection: "alternate",
			animationTimingFunction: "ease-in-out",
		},
		"@keyframes progress": {
			"0%": { backgroundPosition: "0 0" },
			"100%": { backgroundPosition: "-70px 0" },
		},
	};
});

const FilesView = (props) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const snackbar = useSnackbar();
	const {
		userId,
		tripId,
		carrierId,
		data,
		isPublicView,
		isEnabledFilesAnalysis,
		native,
		errorFiles,
		setLoading,
		loading,
	} = props;

	const [files, setFiles] = useState(data);
	const [fileType, setFileType] = useState(null);
	const [index, setIndex] = useState(null);
	const [confirmDialog, setConfirmDialog] = useState({});
	const [warnings, setWarnings] = useState();
	const [ignoreWarnings, setIgnoreWarnings] = useState(false);
	const [isWarningDialogOpen, setIsWarningOpen] = useState(false);
	const [callActionOnce, setCallActionOnce] = useState(null);

	const headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};

	useEffect(() => {
		if (ignoreWarnings) {
			handleAcceptDialog(confirmDialog?.type);
		}
		//eslint-disable-next-line
	}, [ignoreWarnings]);

	useEffect(() => {
		setFiles(data);
		//eslint-disable-next-line
	}, [data]);

	const onClickFileLink = (link) => {
		if (native) return window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "FILE_PREVIEW", url: link }));
		dispatch(
			openFormDialog({
				viewId: "FILE_VISUALIZER_VIEW",
				dataIds: {
					urlFilePreview: link,
				},
			})
		);
	};

	const handleAcceptDialog = async (type, options = {}) => {
		let data;
		let fileIdDeleted;

		setLoading(true);

		if (type === "EDIT") {
			const copy = files.map((item) => ({ ...item }));
			copy[options.index ?? index].type = options.fileType ?? fileType;
			data = [...copy];
		} else if (type === "DELETE") {
			const copy = files.map((item) => ({ ...item }));
			fileIdDeleted = copy[index]?.file_id?.[0];
			copy.splice(index, 1);
			data = [...copy];
		}
		setConfirmDialog({ open: false, type });
		await onSubmit(data, type, fileIdDeleted);
	};

	const onSubmit = async (data, mode, fileIdDeleted) => {
		setIgnoreWarnings(false);
		setIsWarningOpen(false);

		const url = isPublicView
			? `api/public/url/booking/carrier/${carrierId}/trips/${tripId}/files/type/pod_files`
			: `api/booking/carrier/${carrierId}/trips/${tripId}/files/type/pod_files`;

		const dataSend = { data: { pod_files: data, ignoreWarnings: ignoreWarnings, action: mode, fileIdDeleted } };
		const params = isPublicView ? { options_public_user_context: userId } : {};

		axios
			.create({ baseURL: global.SERVER_NAME, headers: headers })
			.put(url, dataSend, { headers, params })
			.then((res) => {
				dispatch(incrementDataRevision({ event: "tripsRevision" }));
				dispatch(incrementDataRevision({ event: "filesRevision" }));
				if (carrierId && !callActionOnce) {
					setCallActionOnce(true);
					dispatch(fetchActions({ carrierId }));
					if (native) {
						window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_TRIP_LIST", data: carrierId }));
					}
					dispatch(incrementDataRevision({ event: "profileRevision" }));
				}

				if (mode === "DELETE") fileService.remove(files[index]?.file_id?.[0]);
				setFiles(data);
				setLoading(false);
			})
			.catch((error) => {
				let response = error?.response;
				let err = { status: response?.status, errors: response?.data?.errors };
				const warnings = err?.errors?.[0]?.metadata?.warnings;
				setLoading(false);
				setWarnings(warnings);
				if (!warnings?.length) {
					showSnackbar(snackbar, err?.errors?.[0]?.message ?? "Error updating", "error");
				} else {
					setIsWarningOpen(true);
				}
			});
	};

	const getSelect = ({ index, fileType }) => {
		return (
			<Select
				className={"min-w-180 max-w-240 w-full h-32 mt-8"}
				defaultValue={"other"}
				value={fileType}
				onChange={(event) => {
					if (index >= 0) {
						handleAcceptDialog("EDIT", { index, fileType: event.target.value });
						return;
					}
					setFileType(event.target.value);
				}}
			>
				<MenuItem value="pod">
					<Typography display="inline">Bill Of Lading</Typography>
				</MenuItem>
				<MenuItem value="rate_confirmation">
					<Typography display="inline">Rate Confirmation</Typography>
				</MenuItem>
				<MenuItem value="proof_of_delivery">
					<Typography display="inline">Proof Of Delivery</Typography>
				</MenuItem>
				<MenuItem value="packing_list">
					<Typography display="inline">Packing List</Typography>
				</MenuItem>
				<MenuItem value="lumper_receipt">
					<Typography display="inline">Lumper Receipt</Typography>
				</MenuItem>
				<MenuItem value="dock/warehouse_receipt">
					<Typography display="inline">Dock/Warehouse Receipt</Typography>
				</MenuItem>
				<MenuItem value="scale_or_weight_receipt">
					<Typography display="inline">Scale or Weight Receipt</Typography>
				</MenuItem>
				<MenuItem value="cargo_picture">
					<Typography display="inline">Cargo Picture</Typography>
				</MenuItem>
				<MenuItem value="display_screen">
					<Typography display="inline">Display Screen</Typography>
				</MenuItem>
				<MenuItem value="other">
					<Typography display="inline">Other</Typography>
				</MenuItem>
			</Select>
		);
	};

	if (errorFiles) {
		return (
			<div className="h-full text-center flex items-center">
				<Typography className="w-full text-12 -mt-24" color="error">
					{errorFiles}
				</Typography>
			</div>
		);
	}

	return (
		<div className={loading ? "pointer-events-none" : ""}>
			<DisplaySection
				classes={{
					root: "w-full pr-20" + (files?.length > 0 ? " flex " : " hidden "),
				}}
				title="Additional Documents"
			>
				<div className={!native ? "mb-12" : ""}>
					{files?.length > 0 &&
						files?.map((file, index) => {
							const analizeStatus = file?.analize?.status;

							return (
								<div className="flex content-center justify-center space-x-10 mt-5">
									<div className="flex flex-row space-between w-full items-center content-center">
										<div className="flex flex-col">
											<div className="flex flex-row">
												<Link
													className="text-13 ml:text-14 break-normal py-4"
													key={file?.file_id?.[0]}
													onClick={(e) =>
														onClickFileLink(fileService.buildLink(file?.file_id?.[0], false, isPublicView))
													}
												>
													{file.description}
												</Link>
												{analizeStatus === "DONE" && isEnabledFilesAnalysis && (
													<FilesIconAnalize data={file?.analize} style={{ icon: native ? "mt-3 text-20" : "mt-6" }} />
												)}
											</div>
											{native && (
												<div className="flex flex-row -mt-6">
													<Typography className={"text-grey-500 text-11 pr-16"}>
														{ADDITIONAL_FILES_TYPES_CONVERTION[file.type]}
													</Typography>
												</div>
											)}
											{["PROGRESS", "ERROR"].includes(analizeStatus) && isEnabledFilesAnalysis && (
												<div className="flex flex-row -mt-6">
													{STATUS[analizeStatus].icon && (
														<Icon className={`text-13 mr-3 mt-1 ${STATUS[analizeStatus].icon.style}`}>
															{STATUS[analizeStatus].icon.value}
														</Icon>
													)}
													<Typography
														className={`text-grey-700 text-11 pr-16 ${
															analizeStatus === "PROGRESS" ? classes.flicker : ""
														}`}
													>
														{STATUS[analizeStatus].label}
													</Typography>
												</div>
											)}
										</div>
									</div>

									{native ? (
										<IconButton
											onClick={() => {
												setIndex(index);
												setFileType(file.type);
												setConfirmDialog({
													open: true,
													title: "Edit File",
													type: "EDIT",
													closeMsg: "Close",
													acceptMsg: "Edit",
												});
											}}
										>
											<Icon className="text-20 -mr-8">edit</Icon>
										</IconButton>
									) : (
										<>{getSelect({ index, fileType: file.type })}</>
									)}

									<IconButton
										onClick={() => {
											setIndex(index);
											setConfirmDialog({
												open: true,
												message: "Are you sure you want to delete file?",
												type: "DELETE",
												closeMsg: "Close",
												acceptMsg: "Delete",
											});
										}}
									>
										<Icon className="text-20" color="error">
											delete
										</Icon>
									</IconButton>
								</div>
							);
						})}
				</div>
			</DisplaySection>

			<SmarthopConfirmDialog
				open={confirmDialog?.open}
				title={confirmDialog?.title}
				handleClose={() => setConfirmDialog({})}
				handleAccept={() => handleAcceptDialog(confirmDialog?.type)}
				closeMsg={confirmDialog?.closeMsg}
				acceptMsg={confirmDialog?.acceptMsg}
				maxWidth="lg"
			>
				{confirmDialog?.type === "DELETE" ? (
					<Typography className="text-16">{confirmDialog?.message}</Typography>
				) : (
					<>{getSelect({ fileType })}</>
				)}
			</SmarthopConfirmDialog>

			{warnings?.length && (
				<WarningConfirmDialog
					open={isWarningDialogOpen}
					warnings={warnings}
					onAccept={() => {
						setWarnings();
						setIgnoreWarnings(true);
					}}
					onClose={() => setWarnings()}
				/>
			)}
		</div>
	);
};
export default FilesView;
