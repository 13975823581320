import { useEffect, useRef, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import PageWrapper from "app/main/common/PageWrapper";
import { axleDriverImportNew, SmarthopList } from "@smarthop/list";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { importAxleDrivers } from "app/services/carrierServices";
import { getCarrierId } from "app/services/LoginService";
import { setSetupDriverAxleSyncSubmitEnabled } from "app/store/auth/registerSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

// eslint-disable-next-line
const phoneRegExp = /^(\s*|(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?))?$/;
const phoneReg = new RegExp(phoneRegExp);

const validateEmail = (email) => {
	return email.match(
		// eslint-disable-next-line
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);
};

export default function SyncDrivers({ setupCarrierId, stepData, loading, setLoading, switchToStep }) {
	const carrierId = setupCarrierId ?? getCarrierId();

	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const submitRevision = useSelector(({ tools }) => tools.revision["setupDriverAxleSyncSubmitRevision"]);
	const submitRevisionRef = useRef(submitRevision);
	const drivers = useMemo(() => {
		return stepData?.axleDrivers?.items?.filter?.((d) => !d.deleted && !d.imported) ?? [];
	}, [stepData?.axleDrivers?.items]);
	const config = useMemo(() => axleDriverImportNew(loading, setLoading), [loading, setLoading]);

	const onSubmitData = useCallback(async () => {
		if (!drivers?.length) {
			return switchToStep(3);
		}
		setLoading(true);
		importAxleDrivers(
			carrierId,
			drivers.map((d) => d.id)
		)
			.then((results) => {
				const failedToSave = Object.keys(results).filter((id) => {
					return results[id] !== true;
				});
				const recordsSaved = Object.keys(results).filter((id) => {
					return results[id] === true;
				});

				if (failedToSave.length > 0) {
					showSnackbar(
						snackbar,
						(recordsSaved.length > 0 ? `${recordsSaved.length} Records imported. ` : "") +
							`${failedToSave.length} Records failed to import: ${results[failedToSave[0]]}`,
						"error"
					);
					dispatch(incrementDataRevision({ event: "profileRevision" }));
					dispatch(incrementDataRevision({ event: "setupDataRevision" }));
				} else {
					showSnackbar(snackbar, `${recordsSaved.length} Records Imported`, "success");
					dispatch(incrementDataRevision({ event: "profileRevision" }));
					dispatch(incrementDataRevision({ event: "setupRevision" }));
					dispatch(incrementDataRevision({ event: "setupDataRevision" }));
				}
			})
			.finally(() => setLoading(false));
	}, [drivers, dispatch, setLoading, carrierId, switchToStep, snackbar]);

	const submitEnabled = useMemo(() => {
		const errors = [];
		drivers?.forEach((d) => {
			if (!d.email || !validateEmail(d.email)) {
				errors.push(d);
			} else if (d.phone && !phoneReg.test(d.phone)) {
				errors.push(d);
			} else if (!d.name) {
				errors.push(d);
			}
		});
		return errors.length === 0;

		// eslint-disable-next-line
	}, [drivers, stepData]);

	useEffect(() => {
		if (submitRevision && submitRevision !== submitRevisionRef.current && submitEnabled) {
			submitRevisionRef.current = submitRevision;
			onSubmitData();
		}
		// eslint-disable-next-line
	}, [submitRevision]);

	useEffect(() => {
		dispatch(setSetupDriverAxleSyncSubmitEnabled(submitEnabled));
	}, [dispatch, submitEnabled]);

	return (
		<PageWrapper
			classes={{ content: "" }}
			minimalView={true}
			titleActions={[
				{
					action: "add_driver",
					label: "Add Driver",
					dialog: { formId: "AXLE_SYNC_DRIVER_ADD", mode: "CREATE", dataIds: { carrierId } },
				},
			]}
		>
			<SmarthopList
				isView={true}
				urlKey="importerStepDriver"
				key="importerStepDriver"
				mode="TABLE"
				config={config}
				dataIds={{ carrierId }}
			/>
		</PageWrapper>
	);
}
