import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";

// List
import { SmarthopList } from "@smarthop/list";
import entityLedgerList from "@smarthop/list/configs/entityLedgerList";

// Dependencies
import PageTabWrapper from "app/main/common/PageTabWrapper";
import PaywallDialog from "app/main/billing/paywall/PaywallDialog.js";

// Pages
import TripPayeesDetailsPayrollPage from "../trips/TripPayeesDetailsPayrollPage";

// Services
import { getRoleLabel } from "app/services/LoginService";

// Utils

const TripPayrollPage = ({ trip, dataIds, isView }) => {
	const tripId = dataIds?.tripId;
	const role = useMemo(() => getRoleLabel(), []);
	const isManager = ["administrator", "carrier", "carrier_manager"].includes(role);

	const [paywallDialog, setPaywallDialog] = useState({});

	const openPaywallView = () => {
		setPaywallDialog({
			open: true,
			dataIds: {
				type: "default",
				data: { callbackKey: "TRIP_VIEW", dataIdsInherited: { ...dataIds, initTab: "PAYROLL" } },
			},
		});
	};

	if (!isManager) {
		return (
			<div className="my-12">
				<Alert severity="info">
					Need to use payroll?
					<Link className="cursor-pointer px-4" onClick={() => openPaywallView()}>
						<span className="text-primary font-semibold underline">Click here</span>
					</Link>
					here to learn how.
				</Alert>
				<PaywallDialog data={paywallDialog} onHandlerPaywall={() => setPaywallDialog({})} />
			</div>
		);
	}

	let tabsComponents = [
		{
			label: "Settings",
			component: <TripPayeesDetailsPayrollPage trip={trip} dataIds={dataIds} isView={true} />,
		},
		{
			label: "Ledger",
			component: (
				<SmarthopList
					key="ledger-table"
					mode="table"
					isView={2}
					config={entityLedgerList({ isTrip: true })}
					dataIds={{ tripId, carrierId: trip.carrier }}
				/>
			),
		},
	];

	return <PageTabWrapper title="Payroll" variant="narrow" isView={isView} tabs={tabsComponents} />;
};

export default TripPayrollPage;
