import { createEntityAdapter, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import moment from "moment";
import axios from "axios";
import { global } from "app/services/requestUtil";
import { DEFAULT_SOFT_FILTERS_VALUES, DEFAULT_HARD_FILTERS_VALUES } from "app/main/searchV3/utils/filtersUtils";
import { isEnabled } from "app/services/featureStorageService";

export const FINISHED_STATES = ["FINISHED", "FINISHED_WITH_FAILURES", "EXPIRED"];

//Get Searches from sessionStorage
export const fetchSearchesSaved = createAsyncThunk("search/list/fetch", async () => {
	const savedSearches = sessionStorage.getItem("SEARCH_LIST");
	if (savedSearches) {
		let parsedSearches = JSON.parse(savedSearches);
		return parsedSearches;
	} else {
		return {
			EMPTY: { filters: {} },
		};
	}
});

export const createSearch = createAsyncThunk(
	"search/create",
	async ({ userId, filters, softFilters }, { rejectWithValue }) => {
		console.time("[SearchV3Slice]Create Search");
		console.time("[SearchV3Slice]First Render");
		let headers = {
			"Content-Type": "application/json",
			Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
			timeout: 15000,
		};
		const response = await axios
			.create({ baseURL: global.SERVER_NAME, headers: headers })
			.post(`api/search/users/${userId}/requests`, {
				headers: headers,
				data: filters,
			})
			.catch(function (error) {
				throw new Error(error.response.data.errors?.[0]?.message ?? error.response.data.message ?? error.message);
			});

		if (response?.error) return rejectWithValue(response.error);

		return { filters: filters, softFilters, data: response.data };
	}
);

export const fetchSearchResults = createAsyncThunk(
	"search/results/fetch",
	async ({ searchId, userId, options }, { rejectWithValue }) => {
		console.time("[SearchV3Slice]Fetch Results");
		let headers = {
			"Content-Type": "application/json",
			Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
		};
		const response = await axios
			.create({ baseURL: global.SERVER_NAME, headers: headers })
			.get(`api/search/v3/users/${userId}/requests/${searchId}/results`, {
				headers: headers,
			})
			.catch(function (error) {
				throw new Error(error.response.data.errors?.[0]?.message ?? error.response.data.message ?? error.message);
			});
		return {
			searchId,
			data: response.data,
			options,
		};
	}
);

//Slice object structure
/*
	{
		SEARCH_ID: {
			state: 	"QUEUEING","PROCESSING","FINISHED","FINISHED_PRELOADED",
							"FINISHED_MANDATORY","FINISHED_WITH_FAILURES","PARTIALLY_FINISHED",
							"EXPIRED","FAILED","FAILED_STUCK","UNKNOWN"
			stats: { filtered }
			filters: {key:value}
			softFilters: {key:value}
			results: [{load}]
			errors: [{error}],
			processing: true/false,
			order: int
		}
	}
*/

const searchesAdapter = createEntityAdapter({});
const initialState = searchesAdapter.upsertMany(
	searchesAdapter.getInitialState({
		searches: {
			EMPTY: { filters: {} },
		},
		currentSearch: "EMPTY",
		errors: null,
	}),
	[]
);

const searchV3Slice = createSlice({
	name: "search/list",
	initialState,
	reducers: {
		setCurrentSearch: (state, action) => {
			if (action.payload) {
				const { searchId } = action.payload;
				if (searchId && searchId !== "EMPTY") {
					if (!state.searches[searchId]) {
						//Create a placeholder for tab loading
						state.searches[searchId] = { processing: true, order: 0 };
					}
					state.currentSearch = searchId;
				} else {
					state.currentSearch = "EMPTY";
					state.searchBlocked = false;
				}
			}
		},
		removeSearch: (state, action) => {
			delete state.searches[action.payload];
			state.searches["EMPTY"] = { filters: DEFAULT_HARD_FILTERS_VALUES, softFilters: DEFAULT_SOFT_FILTERS_VALUES };
			state.currentSearch = "EMPTY";
			state.searchBlocked = false;
		},
		cleanErrors: (state, action) => {
			state.errors = undefined;
		},
		setSearchParams: (state, action) => {
			const {
				searchId,
				hardFilters,
				softFilters,
				sortConfig,
				scrollOffset,
				searchState,
				override,
				processing,
				versions,
				pipelineErrors,
				pipelineWarnings,
				pipelinesStates,
			} = action.payload;

			if (searchId && state.searches[searchId]) {
				// if (!state.searches[searchId]) state.searches[searchId] = {};
				if (hardFilters && override?.hardFilters) {
					state.searches[searchId].filters = hardFilters;
				} else if (hardFilters) {
					state.searches[searchId].filters = {
						...state.searches[searchId].filters,
						...hardFilters,
					};
				}
				if (softFilters && override?.softFilters) {
					state.searches[state.currentSearch].softFilters = softFilters;
				} else if (softFilters) {
					state.searches[searchId].softFilters = {
						...state.searches[searchId].softFilters,
						...softFilters,
					};
				}
				if (sortConfig) {
					state.searches[searchId].sortConfig = sortConfig;
				}
				if (searchState) {
					state.searches[searchId].state = searchState;
				}
				if (scrollOffset) {
					state.searches[searchId].scrollOffset = scrollOffset;
				}
				if (processing !== undefined) {
					state.searches[searchId].processing = processing;
				}
				if (pipelineErrors !== undefined) {
					state.searches[searchId].pipelineErrors = pipelineErrors;
				}
				if (pipelineWarnings !== undefined) {
					state.searches[searchId].pipelineWarnings = pipelineWarnings;
				}
				if (pipelinesStates !== undefined) {
					state.searches[searchId].pipelinesStates = pipelinesStates;
				}
				if (versions !== undefined) {
					state.searches[searchId].stats = { ...state.searches[searchId].stats, ...{ versions } };
				}
			}
		},
		addSearchStatistic: (state, action) => {
			if (action.payload) {
				const { searchId, statName, value, data, operation } = action.payload;
				const updateCondition = isEnabled("SEARCH_PAGINATION_NEW") ? true : !state.searches[searchId]?.processing;
				if (searchId && !!state.searches[searchId] && updateCondition) {
					if (!state.searches[searchId].stats) state.searches[searchId].stats = {};
					//The stats can have a set of results to add to the current list
					if (data) {
						state.searches[searchId].results =
							state.searches[searchId].results?.length > 0
								? [
										...new Map(
											[...state.searches[searchId].results, ...data].map((item) => [item.tripid, item])
										).values(),
								  ]
								: data;
						if (statName)
							state.searches[searchId].stats[statName] = (state.searches[searchId].stats[statName] ?? 0) + value;
					} else if (operation) {
						if (operation === "add")
							state.searches[searchId].stats[statName] = (state.searches[searchId].stats[statName] ?? 0) + value;
						if (operation === "remove")
							state.searches[searchId].stats[statName] = (state.searches[searchId].stats[statName] ?? 0) - value;
					} else {
						state.searches[searchId].stats[statName] = value;
					}
				}
			}
		},
		addSearchMultipleStatistics: (state, action) => {
			if (action.payload) {
				const { searchId, stats } = action.payload;
				const updateCondition = isEnabled("SEARCH_PAGINATION_NEW") ? true : !state.searches[searchId]?.processing;
				if (searchId && !!state.searches[searchId] && updateCondition) {
					if (!state.searches[searchId].stats) state.searches[searchId].stats = {};
					state.searches[searchId].stats = { ...state.searches[searchId].stats, ...stats };
				}
			}
		},
		updateLoadData: (state, action) => {
			if (action.payload) {
				const { searchId, loadId, value } = action.payload;
				if (state.searches[searchId]?.results) {
					for (let i = 0; i < state.searches[searchId].results.length; i++) {
						if (state.searches[searchId].results[i].tripid === loadId) {
							state.searches[searchId].results[i] = { ...state.searches[searchId].results[i], ...value };
							break;
						}
					}
				}
			}
		},
		acknowledgeNew: (state, action) => {
			if (action.payload) {
				const { searchId } = action.payload;
				let copyResults = [];
				if (state.searches[searchId]?.results) {
					for (let i = 0; i < state.searches[searchId].results.length; i++) {
						let loadCopy = { ...state.searches[searchId].results[i] };
						loadCopy.new = false;
						copyResults.push(loadCopy);
					}
					state.searches[searchId].results = copyResults;
					state.searches[searchId].stats.new = 0;
				}
			}
		},
		coverLoads: (state, action) => {
			if (action.payload) {
				const { searchId, loads } = action.payload;
				let copyResults = [];
				if (state.searches[searchId]?.results) {
					for (let i = 0; i < state.searches[searchId].results.length; i++) {
						let cachedLoad = state.searches[searchId].results[i];
						const tripId = cachedLoad.tripid;
						if (loads[tripId]) cachedLoad = { ...cachedLoad, ...{ deleted: true, deletedAt: loads[tripId].deletedAt } };
						copyResults.push(cachedLoad);
					}
					state.searches[searchId].results = copyResults;
				}
			}
		},
		saveCheckAvailability: (state, action) => {
			if (action.payload) {
				const { searchId, loads } = action.payload;
				if (state.searches[searchId]) {
					const lastDate = moment();
					const lastAvailability = loads.reduce((indexedObject, load) => {
						indexedObject[load.tripid] = { lastCheck: lastDate };
						return indexedObject;
					}, {});

					state.searches[searchId].loadsChecked = { ...state.searches[searchId]?.loadsChecked, ...lastAvailability };
					state.searches[searchId].lastCheck = lastDate;
				}
			}
		},
		unblockSearch: (state, action) => {
			state.searchBlocked = false;
		},
	},
	extraReducers: {
		[fetchSearchesSaved.fulfilled]: (state, action) => {
			const savedSearches = action.payload;
			for (const key in savedSearches) {
				if (!state.searches[key]) {
					state.searches[key] = savedSearches[key];
				} else {
					//Don't override filters if we already have them
					if (!state.searches[key].filters || key === "EMPTY") state.searches[key].filters = savedSearches[key].filters;
					if (!state.searches[key].softFilters || key === "EMPTY")
						state.searches[key].softFilters = savedSearches[key].softFilters;
					state.searches[key].order = savedSearches[key].order;
				}
			}
		},
		[fetchSearchesSaved.pending]: (state, action) => {},
		[fetchSearchesSaved.rejected]: (state, action) => {
			console.log("Getting saved searches failed");
		},
		[createSearch.fulfilled]: (state, action) => {
			console.timeEnd("[SearchV3Slice]Create Search");
			delete state.blockTabs;
			const searchId = action.payload.data.id;
			const allowSearch = action.payload.data.allowSearch;
			const { filters, softFilters } = action.payload;

			if (allowSearch === false) {
				state.searchBlocked = !allowSearch;
				state.searches["EMPTY"].processing = false;
			} else {
				const jobState = action.payload.state;
				const jobStatus = action.payload.status;
				let tabOrder = state.searches ? Object.keys(state.searches).length : 0; //New search at right

				if (state.currentSearch !== "EMPTY" && searchId !== state.currentSearch) {
					tabOrder = state.searches[state.currentSearch].order; //preserve original tab order
					delete state.searches[state.currentSearch];
					state.currentSearch = searchId;
				}
				//Cleanup empty tab if needed
				if (state.currentSearch === "EMPTY") {
					state.searches["EMPTY"] = { filters: {} };
					state.currentSearch = searchId;
				}
				//Save current filters in new search
				state.searches[searchId] = {
					filters,
					softFilters,
					attempts: 0,
					state: jobState,
					status: jobStatus,
					processing: true,
					order: tabOrder,
				};
			}
		},
		[createSearch.pending]: (state, action) => {
			delete state.errors;
			delete state.searchBlocked;
			state.blockTabs = true;
			if (state.searches[state.currentSearch]) {
				delete state.searches[state.currentSearch].results;
				delete state.searches[state.currentSearch].error;
				delete state.searches[state.currentSearch].state;
				delete state.searches[state.currentSearch].attempts;
				delete state.searches[state.currentSearch].stats;
			}
			state.searches[state.currentSearch].processing = true;
		},
		[createSearch.rejected]: (state, action) => {
			delete state.blockTabs;
			state.searches[state.currentSearch].processing = false;
			state.searches[state.currentSearch].error = action.error.message ?? "Oops, search creation failed...";
		},
		[fetchSearchResults.fulfilled]: (state, action) => {
			const searchId = action.payload.searchId;
			const options = action.payload.options;
			const { results, filters, versions, pipelineErrors, pipelineWarnings, pipelinesStates } = action.payload.data;
			const searchState = action.payload.data.state;
			const newLoads = results.filter((load) => load.new).length;
			console.timeEnd("[SearchV3Slice]Fetch Results");

			if (state.searches[searchId]) {
				state.searches[searchId] = {
					...state.searches[searchId],
					results,
					state: searchState,
					stats: { ...state.searches[searchId].stats, ...{ filtered: results.length, new: newLoads ?? 0, versions } },
					attempts: state.searches[searchId].attempts + 1,
					processing: results.length > 0 || FINISHED_STATES.includes(searchState) ? false : true,
					pipelineErrors,
					pipelineWarnings,
					pipelinesStates,
					...(state.searches[searchId].filters ? state.searches[searchId].filters : filters),
				};
				state.searches["EMPTY"].processing = false;
				if (options?.setAsCurrent) state.currentSearch = searchId;
			}
		},
		[fetchSearchResults.pending]: (state, action) => {
			const searchId = action?.meta?.arg?.searchId;
			if (state.searches[searchId]?.error) delete state.searches[searchId].error;
			if (state.searches[searchId] && !state.searches[searchId].attempts) state.searches[searchId].processing = true;
		},
		[fetchSearchResults.rejected]: (state, action) => {
			state.searches[state.currentSearch].processing = false;
			state.searches[state.currentSearch].error = action.error.message ?? "Oops, results fetch failed...";
		},
	},
});

export const {
	setCurrentSearch,
	removeSearch,
	cleanErrors,
	addSearchStatistic,
	addSearchMultipleStatistics,
	setSearchParams,
	updateLoadData,
	acknowledgeNew,
	coverLoads,
	saveCheckAvailability,
	unblockSearch,
} = searchV3Slice.actions;

export default searchV3Slice.reducer;
