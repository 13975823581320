import CarrierUsersEditView from "../../profile/carriers/CarrierUsersEditView";

function CreateDriver({ data, switchToStep }) {
	return (
		<div className="w-full">
			<CarrierUsersEditView
				key={"setup_driver"}
				mode={"CREATE"}
				dataIds={{
					carrierId: data.setup_onboarding_carrier_id,
					truck: data.first_truck,
					mode: "CREATE",
					version: "LITE",
					switchToStep,
					origin: "DRIVER",
				}}
			/>
		</div>
	);
}

export default CreateDriver;
