import { Icon, Typography } from "@material-ui/core";
import { isStepAllowed } from "./onboardUtil";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
	numberCircle: {
		borderRadius: "50%",
		width: "36px",
		height: "36px",
		padding: "6px",
		border: "2px solid #fff",
		color: "white",
		textAlign: "center",
		marginRight: "8px",
	},
	numberCircleActive: {
		marginRight: "8px",
		borderRadius: "50%",
		width: "36px",
		height: "36px",
		padding: "6px",
		border: "2px solid #fff",
		color: "white",
		textAlign: "center",
		backgroundColor: "#ef6631",
	},
	section: {
		"&:hover, &:focus": {
			backgroundColor: "#062246",
		},
	},
}));

function OnboardSidebar({ step, config, data, switchToStep, isMobile, setMobileSidebarOpen }) {
	const classes = useStyles();
	const renderItem = useCallback(
		(sectionStep) => {
			const isAllowed = isStepAllowed({ step: sectionStep, config, data });
			const sectionClass =
				"py-16 px-16 " +
				(step === sectionStep
					? config.itemClass
						? config.itemClass
						: "  "
					: !isAllowed || data?.onboardingStatus === "REJECTED"
					? "opacity-80 cursor-not-allowed"
					: "cursor-pointer ");

			return (
				<div
					key={"step_" + sectionStep}
					style={{ width: "250px" }}
					className={clsx(sectionClass, classes.section)}
					onClick={() => {
						if (isAllowed) {
							switchToStep(sectionStep);
							setMobileSidebarOpen(false);
						}
					}}
				>
					<div className="flex flex-row content-center justify-start items-center w-full">
						<div className={step === sectionStep ? classes.numberCircleActive : classes.numberCircle}>
							{sectionStep + 1}
						</div>
						<div className="flex flex-col grow w-full">
							<div className="flex flex-row justify-between content-center items-center grow w-full">
								<Typography className="text-15 text-white" style={{ maxWidth: "80%" }}>
									{config.list[sectionStep].title}
								</Typography>
								{config.list[sectionStep]?.isComplete(data) && <Icon className="text-green-200">check</Icon>}
							</div>
							{config.list[sectionStep]?.isOptional?.(data) && (
								<Typography className="text-11 text-white">Optional</Typography>
							)}
						</div>
					</div>
				</div>
			);
		},
		[config, data, step, classes, switchToStep, setMobileSidebarOpen]
	);

	return (
		<div className="flex flex-col items-center justify-center content-center w-full">
			<div className="flex flex-col items-start justify-start" style={{ width: "250px" }}>
				<div className="flex flex-col w-full">
					{config.sidebarDescription && (
						<Typography color="textSecondary" className="text-13">
							{config.sidebarDescription}
						</Typography>
					)}
					{config.renderSidebarDescription?.({ config, data })}
				</div>
				{config.list.map((s, index) => renderItem(index))}
				<div className="flex w-32 min-h-28" />
			</div>
		</div>
	);
}

export default OnboardSidebar;
