import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { setHasDispatchService } from "app/store/auth/registerSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

function SetupIntro() {
	const dispatch = useDispatch();

	const user = useSelector(({ auth }) => auth.user);
	const authRegister = useSelector(({ auth }) => auth.register);

	const [dispatchService, setDispatchService] = useState(authRegister?.hasDispatchService ?? null);

	useEffect(() => {
		if (user?.userTier !== "TIER_STARTER" || dispatchService) {
			return;
		}
		dispatch(setHasDispatchService("NO"));
		dispatch(incrementDataRevision({ event: "setupRevision" }));
		// eslint-disable-next-line
	}, [user]);

	return (
		<div className="flex flex-col w-full">
			<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal">
				You have an active SmartHop account.
			</Typography>
			<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal">
				Now, we'll walk you through setting up your account, so you can hit the ground running.
			</Typography>
			{user?.userTier !== "TIER_STARTER" ? (
				<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal">
					You can either connect to your ELD provider to set up your account automatically, or enter the necessary
					information manually.
				</Typography>
			) : (
				<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal">
					Please enter the necessary information manually.
				</Typography>
			)}
			{user?.userTier !== "TIER_STARTER" && (
				<>
					<Typography color="textPrimary" className="text-13 mb-8 mt-24 font-semibold">
						Are you a dispatch service?
					</Typography>
					<RadioGroup
						onChange={(event) => {
							setDispatchService(event.target.value);

							dispatch(setHasDispatchService(event.target.value));
							dispatch(incrementDataRevision({ event: "setupRevision" }));
							dispatch(incrementDataRevision({ event: "setupStepsRevision" }));
						}}
						value={dispatchService}
					>
						<FormControlLabel value={"YES"} control={<Radio />} label="Yes" />
						<FormControlLabel value={"NO"} control={<Radio />} label="No" className="-mt-10" />
					</RadioGroup>
					{dispatchService === "YES" && (
						<Typography color="textPrimary" className="text-center text-13 mb-14 mt-14 font-semibold">
							Please complete this process for one of your customers
						</Typography>
					)}
				</>
			)}
		</div>
	);
}

export default SetupIntro;
