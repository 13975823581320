import { useState } from "react";

import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";
import DriverCellView from "./DriverCellView";
import TrailerCellView from "./TrailerCellView";

import Menu from "@material-ui/core/Menu";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

function TruckCellView(props) {
	const [moreMenuEl, setMoreMenuEl] = useState(null);

	const mobile = props.mobile;
	const config = props.config;
	const variant = props.config?.variant ?? props.variant;
	const trailerid = props.view?.metadata?.trailerid;
	const active = props.view?.metadata?.active;
	const carrier = props.view?.metadata?.carrier;
	const posted = props.view?.metadata?.posted;
	const driver_secondary = props.view?.metadata?.driver_secondary;
	const item = props.item;
	const type = props?.view?.metadata?.type;
	const equipment = props.view?.metadata?.equipment;
	const subtype = props.view?.metadata?.subtype;

	let changedTruckDetails;
	let changedTruckClick;
	const currentTruck__view = props.view?.metadata?.currentTruck__view;
	const previousDriver__view = props.view?.metadata?.previousDriver__view;
	const previousSecondaryDriver__view = props.view?.metadata?.previousSecondaryDriver__view;
	const previousTrailer__view = props.view?.metadata?.previousTrailer__view;
	const showTrailerSize = config?.showTrailerSize && item.truck__view?.metadata?.trailer;

	if (currentTruck__view && (previousDriver__view || previousSecondaryDriver__view || previousTrailer__view)) {
		changedTruckDetails = (
			<>
				<IconButton
					onClick={(event) => setMoreMenuEl(event.target)}
					aria-owns={!!moreMenuEl ? "more-menu" : null}
					role="button"
					className={"overflow-visible -ml-6 p-4"}
				>
					<Icon className="text-blue text-24">info</Icon>
				</IconButton>
				<Menu id="more-menu" anchorEl={moreMenuEl} open={!!moreMenuEl} onClose={() => setMoreMenuEl(null)}>
					<div className={"flex flex-col px-20 py-10 space-y-10"}>
						<div className={"flex flex-row items-center"}>
							<Icon className="text-blue text-28 mr-6">info</Icon>
							<Typography className={"text-blue text-12 max-w-180"}>
								Current truck information is different from the information that executed the trip
							</Typography>
						</div>
						<Typography className={"text-grey font-medium text-12 max-w-180"}>Current Truck</Typography>
						<TruckCellView {...props} view={currentTruck__view} />
						{previousDriver__view && (
							<>
								<Typography className={"text-grey font-medium text-12 max-w-180"}>
									Original Trip Primary Driver
								</Typography>
								<DriverCellView {...props} view={previousDriver__view} />
							</>
						)}
						{previousSecondaryDriver__view && (
							<>
								<Typography className={"text-grey font-medium text-12 max-w-180"}>
									Original Trip Secondary Driver
								</Typography>
								<DriverCellView {...props} view={previousSecondaryDriver__view} />
							</>
						)}
						{previousTrailer__view && (
							<>
								<Typography className={"text-grey font-medium text-12 max-w-180"}>Original Trip Trailer</Typography>
								<TrailerCellView
									{...props}
									view={previousTrailer__view}
									config={{ showLength: true, showMaxWeight: true }}
								/>
							</>
						)}
					</div>
				</Menu>
			</>
		);
		changedTruckClick = (event) => {
			setMoreMenuEl(event.target);
		};
	}

	if (props.view?.value === "ANY") {
		return (
			<CellFirstRow
				mobile={mobile}
				view={{ label: props?.view?.label }}
				variant={variant}
				iconButton={config?.iconButton}
			/>
		);
	}

	if (!props.view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	const dialog = {
		viewId: "TRUCK_DETAILS_VIEW",
		dataIds: { carrierId: carrier, truckId: props.view?.value, readOnly: config?.readOnly },
	};

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			left={changedTruckDetails}
			first={
				<CellFirstRow
					mobile={mobile}
					view={config?.mobile ? { label: props?.view?.metadata?.driver__view?.label } : props.view}
					onClick={changedTruckClick}
					variant={variant}
					dialog={changedTruckClick ? null : dialog}
					iconButton={config?.iconButton}
				/>
			}
			second={
				<div className="flex flex-wrap">
					{config?.showCarrierName && (
						<CellSecondRow
							mobile={mobile}
							icon={"business"}
							label={`${item.carrier__view?.label ?? item.carrier}`}
							variant={variant}
						/>
					)}
					{showTrailerSize && (
						<CellSecondRow
							mobile={mobile}
							label={`${
								previousTrailer__view
									? previousTrailer__view.metadata?.subtype ?? previousTrailer__view.metadata?.equipment
									: subtype ?? equipment
							}, ${
								previousTrailer__view ? previousTrailer__view.metadata.trailer : item.truck__view.metadata.trailer
							}'`}
							variant={variant}
						/>
					)}
					{!showTrailerSize && (previousTrailer__view || equipment || subtype) && (
						<CellSecondRow
							mobile={mobile}
							label={`${
								previousTrailer__view
									? previousTrailer__view.metadata?.subtype ?? previousTrailer__view.metadata?.equipment
									: subtype ?? equipment
							}`}
							variant={variant}
						/>
					)}
					{trailerid && (
						<CellSecondRow
							mobile={mobile}
							icon={"rv_hookup"}
							label={previousTrailer__view ? previousTrailer__view.metadata?.trailerid : trailerid}
							variant={variant}
						/>
					)}
					{config?.showPhone && (
						<CellSecondRow
							mobile={mobile}
							icon={"local_phone"}
							label={`${props.view?.metadata?.phone?.length > 0 ? props.view?.metadata?.phone : "No Phone"}`}
							variant={variant}
						/>
					)}
					{config?.showTeam && driver_secondary && (
						<CellSecondRow
							mobile={mobile}
							icon={"people_alt"}
							label={"Team"}
							color={"text-blue font-medium rounded-full"}
							variant={variant}
						/>
					)}
					{config?.showStatus && !active && (
						<CellSecondRow
							mobile={mobile}
							icon={active ? "done" : "close"}
							label={active ? "Active" : "Inactive"}
							color={active ? " text-green-700 " : " text-red-600 "}
							variant={variant}
						/>
					)}
					{config?.showPosted && (
						<CellSecondRow
							mobile={mobile}
							icon={posted ? "settings_input_antenna" : ""}
							label={posted ? "Posted" : ""}
							color={posted ? " text-green-700 " : " text-red-600 "}
							variant={variant}
						/>
					)}
					{config?.showTypeHistory && type && (
						<CellSecondRow mobile={mobile} label={type ?? "No Type"} variant={variant} />
					)}
				</div>
			}
		/>
	);
}

export default TruckCellView;
